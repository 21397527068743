"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SushiV3Provider = void 0;
const chain_1 = require("@zenlink-interface/chain");
const LiquidityProvider_1 = require("./LiquidityProvider");
const UniswapV3Base_1 = require("./UniswapV3Base");
class SushiV3Provider extends UniswapV3Base_1.UniswapV3BaseProvider {
    constructor(chainId, client) {
        const factory = {
            [chain_1.ParachainId.BASE]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
        };
        const stateMultiCall = {
            [chain_1.ParachainId.BASE]: '0x40792858b91D7543A2404936ffbD74CE3900aa6E',
        };
        super(chainId, client, factory, stateMultiCall);
    }
    getType() {
        return LiquidityProvider_1.LiquidityProviders.SushiSwapV3;
    }
    getPoolProviderName() {
        return 'SushiSwapV3';
    }
}
exports.SushiV3Provider = SushiV3Provider;
