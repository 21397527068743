"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UniswapV3Provider = void 0;
const chain_1 = require("@zenlink-interface/chain");
const LiquidityProvider_1 = require("./LiquidityProvider");
const UniswapV3Base_1 = require("./UniswapV3Base");
class UniswapV3Provider extends UniswapV3Base_1.UniswapV3BaseProvider {
    constructor(chainId, client) {
        const factory = {
            [chain_1.ParachainId.ARBITRUM_ONE]: '0x1f98431c8ad98523631ae4a59f267346ea31f984',
            [chain_1.ParachainId.SCROLL_ALPHA]: '0x6E7E0d996eF50E289af9BFd93f774C566F014660',
            [chain_1.ParachainId.BASE]: '0x33128a8fC17869897dcE68Ed026d694621f6FDfD',
            [chain_1.ParachainId.MOONBEAM]: '0x28f1158795A3585CaAA3cD6469CD65382b89BB70',
        };
        const stateMultiCall = {
            [chain_1.ParachainId.ARBITRUM_ONE]: '0x653aE5790B133d4f42F344104eeC47795a6C2388',
            [chain_1.ParachainId.SCROLL_ALPHA]: '0xAFCCA0f68e0883b797c71525377DE46B2E65AB28',
            [chain_1.ParachainId.BASE]: '0x40792858b91D7543A2404936ffbD74CE3900aa6E',
            [chain_1.ParachainId.MOONBEAM]: '0x9927553354aE0442cd234AB6f88582FA6bc84dC2',
        };
        super(chainId, client, factory, stateMultiCall);
    }
    getType() {
        return LiquidityProvider_1.LiquidityProviders.UniswapV3;
    }
    getPoolProviderName() {
        return 'UniswapV3';
    }
}
exports.UniswapV3Provider = UniswapV3Provider;
