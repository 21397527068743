import * as React from 'react'

export const AmplitudeCircle = (props: React.ComponentProps<'svg'>) => (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" {...props}>
        <rect x="0" y="0" width="200" height="200" rx="100" ry="100"
              fill="#4ee59a"
        />
        <g>
            <path
                fill="#1c1c1c"
                d="m68.34,72.35c-2.35,0-4.81,3.07-7.56,9.51-3.21,8.38-6.87,17.91-6.87,17.91,0,0,3.98,12.01,6.21,17.91h0c2.23,5.9,3.51,9.51,9.05,9.51h.02c2.35,0,4.81-3.07,7.56-9.51,3.21-8.38,6.87-17.91,6.87-17.91,0,0-3.98-12.01-6.21-17.91-2.23-5.9-3.51-9.52-9.08-9.51Z"/>
            <path
                fill="#1c1c1c"
                d="m115.38,53.79c-7.73,0-10.91,7.45-12.87,11.58-1.96,4.13-18.88,34.39-18.88,34.39h0s15.87,29.64,18.25,34.39h0c2.37,4.76,6.18,11.58,13.91,11.58s10.91-7.45,12.87-11.58,18.88-34.39,18.88-34.39h0s-15.87-29.64-18.25-34.39-6.18-11.58-13.91-11.58Z"/>
            <path
                fill="#1c1c1c"
                d="m100.44,21.35c-43.29,0-78.38,35.09-78.38,78.38s35.09,78.38,78.38,78.38,78.38-35.09,78.38-78.38S143.73,21.35,100.44,21.35Zm0,141.37c-34.79,0-63-28.2-63-63h0c0-34.79,28.2-63,62.99-63s63,28.2,63,63-28.2,63-63,63Z"/>
        </g>
    </svg>
)
