"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommandCode = void 0;
var CommandCode;
(function (CommandCode) {
    CommandCode[CommandCode["DISTRIBUTE_ERC20_AMOUNTS"] = 3] = "DISTRIBUTE_ERC20_AMOUNTS";
    CommandCode[CommandCode["DISTRIBUTE_ERC20_SHARES"] = 4] = "DISTRIBUTE_ERC20_SHARES";
    CommandCode[CommandCode["WRAP_AND_DISTRIBUTE_ERC20_AMOUNTS"] = 5] = "WRAP_AND_DISTRIBUTE_ERC20_AMOUNTS";
    CommandCode[CommandCode["UNWRAP_NATIVE"] = 6] = "UNWRAP_NATIVE";
    CommandCode[CommandCode["SWAP_UNISWAP_V2"] = 10] = "SWAP_UNISWAP_V2";
    CommandCode[CommandCode["SWAP_ZENLINK_STABLE_POOL"] = 20] = "SWAP_ZENLINK_STABLE_POOL";
})(CommandCode || (exports.CommandCode = CommandCode = {}));
