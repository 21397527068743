"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TraderJoeV2Provider = void 0;
const bignumber_1 = require("@ethersproject/bignumber");
const chain_1 = require("@zenlink-interface/chain");
const router_config_1 = require("@zenlink-interface/router-config");
const abis_1 = require("../abis");
const entities_1 = require("../entities");
const util_1 = require("../util");
const LiquidityProvider_1 = require("./LiquidityProvider");
class TraderJoeV2Provider extends LiquidityProvider_1.LiquidityProvider {
    constructor(chainId, client) {
        super(chainId, client);
        this.BIN_AMOUNT = 30;
        this.poolCodes = [];
        this.factory = {
            [chain_1.ParachainId.ARBITRUM_ONE]: '0x8e42f2F4101563bF679975178e880FD87d3eFd4e',
        };
        this.stateMultiCall = {
            [chain_1.ParachainId.ARBITRUM_ONE]: '0xbA2aF4Bdeeedb43948bcAbDbD68Eb7904ACc4316',
        };
    }
    async getPools(tokens) {
        if (!(this.chainId in this.factory) || !(this.chainId in this.stateMultiCall)) {
            this.lastUpdateBlock = -1;
            return;
        }
        // tokens deduplication
        const tokenMap = new Map();
        tokens.forEach(t => tokenMap.set((0, util_1.formatAddress)(t.address), t));
        const tokensDedup = Array.from(tokenMap.values());
        // tokens sorting
        const tok0 = tokensDedup.map(t => [(0, util_1.formatAddress)(t.address), t]);
        tokens = tok0.sort((a, b) => (b[0] > a[0] ? -1 : 1)).map(([_, t]) => t);
        const pools = [];
        for (let i = 0; i < tokens.length; ++i) {
            const t0 = tokens[i];
            for (let j = i + 1; j < tokens.length; ++j) {
                const t1 = tokens[j];
                pools.push([t0, t1]);
            }
        }
        const poolState = await this.client.multicall({
            allowFailure: true,
            contracts: pools.map(pool => ({
                args: [
                    this.factory[this.chainId],
                    pool[0].address,
                    pool[1].address,
                    BigInt(this.BIN_AMOUNT),
                    BigInt(this.BIN_AMOUNT),
                ],
                address: this.stateMultiCall[this.chainId],
                chainId: chain_1.chainsParachainIdToChainId[this.chainId],
                abi: abis_1.traderjoeV2StateMulticall,
                functionName: 'getFullState',
            })),
        }).catch((e) => {
            console.warn(e.message);
            return undefined;
        });
        pools.forEach((pool, i) => {
            if (poolState?.[i].status !== 'success' || !poolState?.[i].result)
                return;
            poolState[i].result?.forEach((res) => {
                const reserve0 = bignumber_1.BigNumber.from(res.reserve0);
                const reserve1 = bignumber_1.BigNumber.from(res.reserve1);
                if (reserve0.eq(0) || reserve1.eq(0))
                    return;
                const tokenX = res.tokenX;
                const [token0, token1] = pool[0].address.toLowerCase() === tokenX.toLowerCase()
                    ? [pool[0], pool[1]]
                    : [pool[1], pool[0]];
                const totalFee = (0, util_1.getNumber)(res.totalFee);
                const bins = res.binInfos.map(bin => ({
                    id: bin.id,
                    reserve0: bignumber_1.BigNumber.from(bin.reserveX),
                    reserve1: bignumber_1.BigNumber.from(bin.reserveY),
                }));
                const joev2Pool = new entities_1.JoeV2Pool(res.pair, token0, token1, totalFee / 1e18, reserve0, reserve1, totalFee, res.binStep, res.activeId, bins);
                const pc = new entities_1.JoeV2PoolCode(joev2Pool, this.getPoolProviderName());
                this.poolCodes.push(pc);
                ++this.stateId;
            });
        });
    }
    async updatePoolsData() {
        if (!this.poolCodes.length)
            return;
        const poolAddr = new Map();
        this.poolCodes.forEach(p => poolAddr.set(p.pool.address, p.pool));
        const pools = this.poolCodes.map(p => [p.pool.token0, p.pool.token1]);
        const poolState = await this.client.multicall({
            allowFailure: true,
            contracts: pools.map(pool => ({
                args: [
                    this.factory[this.chainId],
                    pool[0].address,
                    pool[1].address,
                    BigInt(this.BIN_AMOUNT),
                    BigInt(this.BIN_AMOUNT),
                ],
                address: this.stateMultiCall[this.chainId],
                chainId: chain_1.chainsParachainIdToChainId[this.chainId],
                abi: abis_1.traderjoeV2StateMulticall,
                functionName: 'getFullState',
            })),
        }).catch((e) => {
            console.warn(e.message);
            return undefined;
        });
        pools.forEach((_, i) => {
            if (poolState?.[i].status !== 'success' || !poolState?.[i].result)
                return;
            poolState[i].result?.forEach((res) => {
                const bins = res.binInfos.map(bin => ({
                    id: bin.id,
                    reserve0: bignumber_1.BigNumber.from(bin.reserveX),
                    reserve1: bignumber_1.BigNumber.from(bin.reserveY),
                }));
                const joev2Pool = poolAddr.get(res.pair);
                if (joev2Pool) {
                    joev2Pool.updateState(bignumber_1.BigNumber.from(res.reserve0), bignumber_1.BigNumber.from(res.reserve1), res.activeId, bins);
                    ++this.stateId;
                }
            });
        });
    }
    _getProspectiveTokens(t0, t1) {
        const set = new Set([
            t0,
            t1,
            ...router_config_1.BASES_TO_CHECK_TRADES_AGAINST[this.chainId],
            ...(router_config_1.ADDITIONAL_BASES[this.chainId]?.[t0.address] || []),
            ...(router_config_1.ADDITIONAL_BASES[this.chainId]?.[t1.address] || []),
        ]);
        return Array.from(set);
    }
    startFetchPoolsData() {
        this.stopFetchPoolsData();
        this.poolCodes = [];
        this.getPools(router_config_1.BASES_TO_CHECK_TRADES_AGAINST[this.chainId]); // starting the process
        this.unwatchBlockNumber = this.client.watchBlockNumber({
            onBlockNumber: (blockNumber) => {
                this.lastUpdateBlock = Number(blockNumber);
                this.updatePoolsData();
            },
            onError: (error) => {
                console.error(error.message);
            },
        });
    }
    async fetchPoolsForToken(t0, t1) {
        await this.getPools(this._getProspectiveTokens(t0, t1));
    }
    getCurrentPoolList() {
        return this.poolCodes;
    }
    stopFetchPoolsData() {
        if (this.unwatchBlockNumber)
            this.unwatchBlockNumber();
    }
    getType() {
        return LiquidityProvider_1.LiquidityProviders.TraderJoeV2;
    }
    getPoolProviderName() {
        return 'TraderJoeV2';
    }
}
exports.TraderJoeV2Provider = TraderJoeV2Provider;
