"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZenlinkProvider = void 0;
const amm_1 = require("@zenlink-interface/amm");
const LiquidityProvider_1 = require("./LiquidityProvider");
const UniswapV2Base_1 = require("./UniswapV2Base");
class ZenlinkProvider extends UniswapV2Base_1.UniswapV2BaseProvider {
    constructor(chainId, client) {
        super(chainId, client);
        this.factory = amm_1.FACTORY_ADDRESS;
        this.initCodeHash = amm_1.INIT_CODE_HASH;
    }
    getType() {
        return LiquidityProvider_1.LiquidityProviders.Zenlink;
    }
    getPoolProviderName() {
        return 'Zenlink';
    }
}
exports.ZenlinkProvider = ZenlinkProvider;
