"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SushiProvider = void 0;
const chain_1 = require("@zenlink-interface/chain");
const LiquidityProvider_1 = require("./LiquidityProvider");
const UniswapV2Base_1 = require("./UniswapV2Base");
class SushiProvider extends UniswapV2Base_1.UniswapV2BaseProvider {
    constructor(chainId, client) {
        super(chainId, client);
        this.factory = {
            [chain_1.ParachainId.ARBITRUM_ONE]: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
        };
        this.initCodeHash = {
            [chain_1.ParachainId.ARBITRUM_ONE]: '0xe18a34eb0e04b04f7a0ac29a6e80748dca96319b42c54d679cb821dca90c6303',
        };
    }
    getType() {
        return LiquidityProvider_1.LiquidityProviders.SushiSwap;
    }
    getPoolProviderName() {
        return 'SushiSwap';
    }
}
exports.SushiProvider = SushiProvider;
