"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StellaSwapV2Provider = void 0;
const chain_1 = require("@zenlink-interface/chain");
const LiquidityProvider_1 = require("./LiquidityProvider");
const UniswapV2Base_1 = require("./UniswapV2Base");
class StellaSwapV2Provider extends UniswapV2Base_1.UniswapV2BaseProvider {
    constructor(chainId, client) {
        super(chainId, client);
        this.factory = {
            [chain_1.ParachainId.MOONBEAM]: '0x68A384D826D3678f78BB9FB1533c7E9577dACc0E',
        };
        this.initCodeHash = {
            [chain_1.ParachainId.MOONBEAM]: '0x48a6ca3d52d0d0a6c53a83cc3c8688dd46ea4cb786b169ee959b95ad30f61643',
        };
    }
    getType() {
        return LiquidityProvider_1.LiquidityProviders.StellaSwapV2;
    }
    getPoolProviderName() {
        return 'StellaSwapV2';
    }
}
exports.StellaSwapV2Provider = StellaSwapV2Provider;
