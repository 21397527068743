"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StablePool = void 0;
const math_1 = require("@zenlink-interface/math");
const Fee_1 = require("../Fee");
const calculations_1 = require("./calculations");
class StablePool {
    constructor(swap, token0, token1, baseSwap) {
        this.swapGasCost = math_1.JSBI.BigInt(60000);
        this.minLiquidity = math_1.JSBI.BigInt(1000);
        this.fee = Fee_1.Fee.MEDIUM;
        this.token0 = token0;
        this.token1 = token1;
        this._swap = swap;
        this._baseSwap = baseSwap;
    }
    get chainId() {
        return this._swap.chainId;
    }
    get reserve0() {
        return this._baseSwap
            ? this._baseSwap.balances[this._baseSwap.getTokenIndex(this.token0)]
            : this._swap.balances[this._swap.getTokenIndex(this.token0)];
    }
    get reserve1() {
        return this._swap.balances[this._swap.getTokenIndex(this.token1)];
    }
    involvesToken(token) {
        return this._swap.involvesToken(token);
    }
    pathOf(token) {
        return Object.assign({
            stable: true,
            input: token,
            output: token.equals(this.token0) ? this.token1 : this.token0,
            pool: this._swap,
        }, this._baseSwap
            ? {
                basePool: this._baseSwap,
                fromBase: token.equals(this.token0),
            }
            : {});
    }
    getOutputAmount(inputAmount) {
        const path = this.pathOf(inputAmount.currency);
        return [(0, calculations_1.getStableSwapOutputAmount)(path, inputAmount)];
    }
}
exports.StablePool = StablePool;
