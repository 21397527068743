"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UniswapV2Provider = void 0;
const chain_1 = require("@zenlink-interface/chain");
const LiquidityProvider_1 = require("./LiquidityProvider");
const UniswapV2Base_1 = require("./UniswapV2Base");
class UniswapV2Provider extends UniswapV2Base_1.UniswapV2BaseProvider {
    constructor(chainId, client) {
        super(chainId, client);
        this.factory = {
            [chain_1.ParachainId.BASE]: '0x8909Dc15e40173Ff4699343b6eB8132c65e18eC6',
        };
        this.initCodeHash = {
            [chain_1.ParachainId.BASE]: '0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f',
        };
    }
    getType() {
        return LiquidityProvider_1.LiquidityProviders.UniswapV2;
    }
    getPoolProviderName() {
        return 'UniswapV2';
    }
}
exports.UniswapV2Provider = UniswapV2Provider;
