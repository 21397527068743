import * as React from 'react'

export function TalismanIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      fill="none"
      height={48}
      viewBox="0 0 49 48"
      width={49}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M37.587 26.746C38.043 27.74 39.387 28.091 40.16 27.318L41.576 25.901C41.9228 25.5467 42.3365 25.2647 42.793 25.0713C43.2496 24.8779 43.7399 24.7769 44.2358 24.7743C44.7316 24.7717 45.223 24.8674 45.6816 25.0559C46.1401 25.2444 46.5568 25.522 46.9074 25.8726C47.258 26.2232 47.5356 26.6398 47.7241 27.0984C47.9126 27.557 48.0083 28.0484 48.0057 28.5442C48.003 29.04 47.9021 29.5304 47.7087 29.9869C47.5153 30.4435 47.2333 30.8572 46.879 31.204L35.428 42.657C34.0212 44.3324 32.2642 45.6791 30.2808 46.6022C28.2974 47.5254 26.1357 48.0025 23.948 48C21.6682 48.002 19.4181 47.4835 17.3692 46.4839C15.3202 45.4843 13.5266 44.03 12.125 42.232L1.09998 31.205C0.409089 30.4992 0.0245173 29.5494 0.0297774 28.5618C0.0350375 27.5741 0.429705 26.6285 1.12807 25.9301C1.82644 25.2317 2.77212 24.8371 3.75975 24.8318C4.74738 24.8265 5.69721 25.2111 6.40298 25.902L7.79898 27.298C8.55498 28.054 9.86698 27.714 10.313 26.742C10.402 26.55 10.45 26.344 10.45 26.133V9C10.45 8.00544 10.8451 7.05161 11.5483 6.34835C12.2516 5.64509 13.2054 5.25 14.2 5.25C15.1945 5.25 16.1484 5.64509 16.8516 6.34835C17.5549 7.05161 17.95 8.00544 17.95 9V17.668C17.95 18.413 18.713 18.919 19.424 18.695C19.874 18.552 20.2 18.143 20.2 17.671V3.75C20.2 2.75544 20.5951 1.80161 21.2983 1.09835C22.0016 0.395088 22.9554 0 23.95 0C24.9445 0 25.8984 0.395088 26.6016 1.09835C27.3049 1.80161 27.7 2.75544 27.7 3.75V17.67C27.7 18.143 28.025 18.552 28.474 18.694C29.185 18.919 29.949 18.413 29.949 17.668V9C29.949 8.00544 30.3441 7.05161 31.0473 6.34835C31.7506 5.64509 32.7044 5.25 33.699 5.25C34.6935 5.25 35.6474 5.64509 36.3506 6.34835C37.0539 7.05161 37.449 8.00544 37.449 9V26.125C37.449 26.34 37.498 26.55 37.588 26.746H37.587Z"
        fill="#FF3D23"
        fillRule="evenodd"
      />
      <path
        d="M35.948 34.5C35.948 34.5 30.575 42 23.948 42C17.321 42 11.948 34.5 11.948 34.5C11.948 34.5 17.321 27 23.948 27C30.575 27 35.948 34.5 35.948 34.5Z"
        fill="#D5FF5C"
      />
      <path
        d="M29.582 34.5C29.592 35.2461 29.4536 35.9868 29.1749 36.679C28.8963 37.3712 28.4829 38.0011 27.9588 38.5323C27.4347 39.0634 26.8103 39.4851 26.1219 39.773C25.4334 40.0608 24.6947 40.209 23.9485 40.209C23.2023 40.209 22.4636 40.0608 21.7752 39.773C21.0867 39.4851 20.4623 39.0634 19.9382 38.5323C19.4141 38.0011 19.0007 37.3712 18.7221 36.679C18.4434 35.9868 18.3051 35.2461 18.315 34.5C18.3347 33.0189 18.937 31.6051 19.9913 30.5648C21.0456 29.5244 22.4673 28.941 23.9485 28.941C25.4297 28.941 26.8514 29.5244 27.9057 30.5648C28.9601 31.6051 29.5623 33.0189 29.582 34.5V34.5Z"
        stroke="#FF3D23"
        strokeWidth={0.733}
      />
      <path
        d="M27.332 34.5C27.332 34.9444 27.2445 35.3844 27.0744 35.795C26.9044 36.2055 26.6551 36.5786 26.3409 36.8928C26.0266 37.207 25.6536 37.4563 25.243 37.6264C24.8325 37.7964 24.3924 37.884 23.948 37.884C23.5036 37.884 23.0636 37.7964 22.653 37.6264C22.2425 37.4563 21.8694 37.207 21.5552 36.8928C21.2409 36.5786 20.9917 36.2055 20.8216 35.795C20.6516 35.3844 20.564 34.9444 20.564 34.5C20.564 33.6025 20.9206 32.7417 21.5552 32.1071C22.1898 31.4725 23.0505 31.116 23.948 31.116C24.8455 31.116 25.7063 31.4725 26.3409 32.1071C26.9755 32.7417 27.332 33.6025 27.332 34.5V34.5Z"
        stroke="#FF3D23"
        strokeWidth={0.733}
      />
      <path
        d="M31.832 34.5C31.8438 35.5428 31.6485 36.5775 31.2576 37.5443C30.8667 38.511 30.2879 39.3907 29.5546 40.1322C28.8214 40.8737 27.9484 41.4624 26.9861 41.8642C26.0238 42.266 24.9913 42.4728 23.9485 42.4728C22.9057 42.4728 21.8732 42.266 20.9109 41.8642C19.9486 41.4624 19.0756 40.8737 18.3424 40.1322C17.6092 39.3907 17.0303 38.511 16.6394 37.5443C16.2485 36.5775 16.0533 35.5428 16.065 34.5C16.0884 32.4246 16.9293 30.4421 18.4052 28.9827C19.8811 27.5233 21.8729 26.7048 23.9485 26.7048C26.0241 26.7048 28.016 27.5233 29.4919 28.9827C30.9678 30.4421 31.8086 32.4246 31.832 34.5V34.5Z"
        stroke="#FF3D23"
        strokeWidth={0.733}
      />
      <path
        d="M34.082 34.5C34.082 40.097 29.545 44.634 23.948 44.634C18.351 44.634 13.814 40.097 13.814 34.5C13.814 28.903 18.351 24.366 23.948 24.366C29.545 24.366 34.082 28.903 34.082 34.5Z"
        stroke="#FF3D23"
        strokeWidth={0.733}
      />
      <path
        d="M25.082 34.5C25.0865 34.6517 25.0605 34.8027 25.0056 34.9442C24.9507 35.0856 24.8679 35.2146 24.7622 35.3235C24.6565 35.4324 24.53 35.519 24.3902 35.5781C24.2505 35.6372 24.1003 35.6677 23.9485 35.6677C23.7968 35.6677 23.6466 35.6372 23.5068 35.5781C23.367 35.519 23.2405 35.4324 23.1348 35.3235C23.0291 35.2146 22.9464 35.0856 22.8914 34.9442C22.8365 34.8027 22.8105 34.6517 22.815 34.5C22.8238 34.2052 22.947 33.9254 23.1586 33.72C23.3703 33.5146 23.6536 33.3997 23.9485 33.3997C24.2434 33.3997 24.5268 33.5146 24.7384 33.72C24.95 33.9254 25.0733 34.2052 25.082 34.5V34.5Z"
        fill="#162BEB"
        stroke="#FF3D23"
        strokeWidth={0.733}
      />
      <path
        d="M23.948 36C24.7764 36 25.448 35.3284 25.448 34.5C25.448 33.6716 24.7764 33 23.948 33C23.1196 33 22.448 33.6716 22.448 34.5C22.448 35.3284 23.1196 36 23.948 36Z"
        fill="#FF3D23"
      />
      <path
        d="M12.48 34.5969C12.4552 34.5647 12.4305 34.5324 12.406 34.4999L12.481 34.4029C12.637 34.2019 12.869 33.9129 13.168 33.5669C13.9505 32.6605 14.7974 31.8116 15.702 31.0269C17.852 29.1709 20.767 27.3669 23.948 27.3669C27.129 27.3669 30.044 29.1709 32.194 31.0269C33.371 32.049 34.4494 33.1793 35.415 34.4029L35.49 34.4999L35.415 34.5969C34.4494 35.8206 33.371 36.9509 32.194 37.9729C30.044 39.8279 27.129 41.6329 23.948 41.6329C20.767 41.6329 17.852 39.8279 15.702 37.9729C14.525 36.9509 13.4466 35.8206 12.481 34.5969H12.48Z"
        stroke="#D5FF5C"
        strokeWidth={0.733}
      />
    </svg>
  )
}
