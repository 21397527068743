"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Vertice = void 0;
const pools_1 = require("./pools");
class Vertice {
    constructor(t) {
        this.token = t;
        (0, pools_1.setTokenId)(this.token);
        this.edges = [];
        this.price = 0;
        this.gasPrice = 0;
        this.bestIncome = 0;
        this.gasSpent = 0;
        this.bestTotal = 0;
        this.bestSource = undefined;
        this.checkLine = -1;
    }
    cleanCache() {
        this.bestIncome = 0;
        this.gasSpent = 0;
        this.bestTotal = 0;
        this.bestSource = undefined;
        this.checkLine = -1;
    }
    getNeibour(e) {
        if (!e)
            return undefined;
        return e.vert0 === this ? e.vert1 : e.vert0;
    }
    getOutputEdges() {
        return this.edges.filter((e) => {
            if (!e.canBeUsed)
                return false;
            if (e.amountInPrevious === 0)
                return false;
            if (e.direction !== (e.vert0 === this))
                return false;
            return true;
        });
    }
    getInputEdges() {
        return this.edges.filter((e) => {
            if (!e.canBeUsed)
                return false;
            if (e.amountInPrevious === 0)
                return false;
            if (e.direction === (e.vert0 === this))
                return false;
            return true;
        });
    }
}
exports.Vertice = Vertice;
