"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PoolCode = void 0;
class PoolCode {
    constructor(pool, poolName) {
        this.pool = pool;
        this.poolName = poolName;
    }
    // the address where should be swap amount of liquidity before the swap
    // returns RouteProcessorAddress if it is a RouteProcessor
    getStartPoint(_leg, _route) {
        return this.pool.address;
    }
    // the address where should be swap amount of liquidity before the swap
    // returns ProtocolExecutorAddress if it is a ProtocolExecutor
    getProtocolExecutorStartPoint(_leg, _route) {
        return PoolCode.ProtocolExecutorAddress;
    }
    getSwapCodeForRouteProcessor2(_leg, _route, _to) {
        return 'unimplemented';
    }
    getSwapCodeForAggregationRouter(_leg, _route, _to) {
        return 'unimplemented';
    }
}
exports.PoolCode = PoolCode;
PoolCode.RouteProcessorAddress = 'RouteProcessor';
PoolCode.ProtocolExecutorAddress = 'ProtocolExecutor';
