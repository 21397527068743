"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardPool = void 0;
const BasePool_1 = require("./BasePool");
class StandardPool extends BasePool_1.BasePool {
    constructor(address, token0, token1, fee, reserve0, reserve1) {
        super(address, token0, token1, fee, reserve0, reserve1);
        this.reserve0Number = Number.parseInt(reserve0.toString());
        this.reserve1Number = Number.parseInt(reserve1.toString());
    }
    updateReserves(res0, res1) {
        this.reserve0 = res0;
        this.reserve0Number = Number.parseInt(res0.toString());
        this.reserve1 = res1;
        this.reserve1Number = Number.parseInt(res1.toString());
    }
    getOutput(amountIn, direction) {
        const x = direction ? this.reserve0Number : this.reserve1Number;
        const y = direction ? this.reserve1Number : this.reserve0Number;
        const output = (y * amountIn) / (x / (1 - this.fee) + amountIn);
        if (y - output < this.minLiquidity)
            throw new Error('CP OutOfLiquidity');
        return { output, gasSpent: this.swapGasCost };
    }
    getInput(amountOut, direction) {
        const x = direction ? this.reserve0Number : this.reserve1Number;
        const y = direction ? this.reserve1Number : this.reserve0Number;
        if (y - amountOut < this.minLiquidity)
            return { input: Number.POSITIVE_INFINITY, gasSpent: this.swapGasCost };
        const input = (x * amountOut) / (1 - this.fee) / (y - amountOut);
        return { input, gasSpent: this.swapGasCost };
    }
    calcCurrentPriceWithoutFee(direction) {
        return this.calcPrice(0, direction, false);
    }
    calcPrice(amountIn, direction, takeFeeIntoAccount) {
        const x = direction ? this.reserve0Number : this.reserve1Number;
        const y = direction ? this.reserve1Number : this.reserve0Number;
        const oneMinusFee = takeFeeIntoAccount ? 1 - this.fee : 1;
        const xf = x / oneMinusFee;
        return (y * xf) / (xf + amountIn) / (xf + amountIn);
    }
    calcInputByPrice(price, direction, takeFeeIntoAccount) {
        const x = direction ? this.reserve0Number : this.reserve1Number;
        const y = direction ? this.reserve1Number : this.reserve0Number;
        const oneMinusFee = takeFeeIntoAccount ? 1 - this.fee : 1;
        const xf = x / oneMinusFee;
        return Math.sqrt(y * xf * price) - xf;
    }
    getLiquidity() {
        return Math.sqrt(this.reserve0Number * this.reserve1Number);
    }
}
exports.StandardPool = StandardPool;
