// src/index.ts
import { ParachainId } from "@zenlink-interface/chain";
var ZENLINK_ENABLED_NETWORKS = [
  ParachainId.MOONBEAM,
  ParachainId.MOONRIVER,
  ParachainId.ASTAR,
  ParachainId.BIFROST_KUSAMA,
  ParachainId.BIFROST_POLKADOT,
  ParachainId.AMPLITUDE,
  ParachainId.PENDULUM
];
var SQUID_HOST_ENDPOINT = "https://squid.subsquid.io";
var LEGACY_SQUID_HOST = {
  [ParachainId.ASTAR]: `${SQUID_HOST_ENDPOINT}/zenlink-astar/graphql`,
  [ParachainId.MOONRIVER]: `${SQUID_HOST_ENDPOINT}/zenlink-moonriver/graphql`,
  [ParachainId.MOONBEAM]: `${SQUID_HOST_ENDPOINT}/zenlink-moonbeam/graphql`,
  [ParachainId.BIFROST_KUSAMA]: `${SQUID_HOST_ENDPOINT}/zenlink-bifrost-kusama-squid/graphql`,
  [ParachainId.BIFROST_POLKADOT]: `${SQUID_HOST_ENDPOINT}/zenlink-bifrost-polkadot/graphql`,
  [ParachainId.ARBITRUM_ONE]: "https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-arbitrum-one",
  [ParachainId.BASE]: "https://api.studio.thegraph.com/query/48211/uniswap-v3-base/version/latest",
  [ParachainId.AMPLITUDE]: `${SQUID_HOST_ENDPOINT}/amplitude-squid/graphql`,
  [ParachainId.PENDULUM]: "https://pendulum.squids.live/pendulum-squid/graphql"
};
var ARCHIVE_HOST = {
  [ParachainId.BIFROST_KUSAMA]: "https://bifrost.explorer.subsquid.io/graphql",
  [ParachainId.BIFROST_POLKADOT]: "https://bifrost-polkadot.explorer.subsquid.io/graphql",
  [ParachainId.AMPLITUDE]: "https://amplitude.explorer.subsquid.io/graphql",
  [ParachainId.PENDULUM]: "https://pendulum.explorer.subsquid.io/graphql"
};
var MARKET_SQUID_HOST = {
  [ParachainId.MOONBEAM]: "https://hayden-subsquid.squids.live/moonbeam-market/graphql"
};
export {
  ARCHIVE_HOST,
  LEGACY_SQUID_HOST,
  MARKET_SQUID_HOST,
  SQUID_HOST_ENDPOINT,
  ZENLINK_ENABLED_NETWORKS
};
