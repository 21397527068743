"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataFetcher = void 0;
const currency_1 = require("@zenlink-interface/currency");
const liquidity_providers_1 = require("../liquidity-providers");
const constant_1 = require("./constant");
class DataFetcher {
    constructor(chainId, client) {
        this.providers = [];
        this.lastProviderStates = new Map();
        // Provider to poolAddress to PoolCode
        this.poolCodes = new Map();
        this.stateId = 0;
        this.chainId = chainId;
        this.client = client;
    }
    _providerIsIncluded(lp, liquidity) {
        if (!liquidity)
            return true;
        if (lp === liquidity_providers_1.LiquidityProviders.NativeWrap)
            return true;
        return liquidity.includes(lp);
    }
    _fillProviders(liquidity) {
        constant_1.LIQUIDITY_PROVIDERS.forEach((providerType) => {
            if (this._providerIsIncluded(providerType, liquidity)) {
                try {
                    const provider = new constant_1.LIQUIDITY_PROVIDERS_MAP[providerType](this.chainId, this.client);
                    this.providers.push(provider);
                }
                catch { }
            }
        });
    }
    startDataFetching(providers) {
        this.stopDataFetching();
        this.poolCodes = new Map();
        this.providers = [
            new liquidity_providers_1.NativeWrapProvider(this.chainId, this.client),
        ];
        this._fillProviders(providers);
        this.providers.forEach(p => p.startFetchPoolsData());
    }
    // To stop fetch pool data
    stopDataFetching() {
        this.providers.forEach(p => p.stopFetchPoolsData());
    }
    async fetchPoolsForToken(t0, t1) {
        const token0 = this.transformToken(t0);
        const token1 = this.transformToken(t1);
        await Promise.all(this.providers.map(p => p.fetchPoolsForToken(token0, token1)));
    }
    getCurrentPoolCodeMap(providers) {
        const result = new Map();
        this.providers.forEach((p) => {
            if (!this._providerIsIncluded(p.getType(), providers))
                return;
            if (p.getCurrentPoolStateId() !== this.lastProviderStates.get(p.getType())) {
                this.lastProviderStates.set(p.getType(), p.getCurrentPoolStateId());
                const poolCodes = p.getCurrentPoolList();
                let pcMap = this.poolCodes.get(p.getType());
                if (pcMap === undefined) {
                    pcMap = new Map();
                    this.poolCodes.set(p.getType(), pcMap);
                }
                poolCodes.forEach(pc => pcMap.set(pc.pool.poolId, pc));
            }
            const pcMap = this.poolCodes.get(p.getType());
            if (pcMap)
                Array.from(pcMap.entries()).forEach(([poolId, pc]) => result.set(poolId, pc));
        });
        return result;
    }
    getCurrentPoolCodeList(providers) {
        const pcMap = this.getCurrentPoolCodeMap(providers);
        return Array.from(pcMap.values());
    }
    getCurrentPoolStateId(providers) {
        let state = 0;
        this.providers.forEach((p) => {
            if (this._providerIsIncluded(p.getType(), providers))
                state += p.getCurrentPoolStateId();
        });
        return state;
    }
    // returns the last processed by all LP block number
    getLastUpdateBlock(providers) {
        let lastUpdateBlock;
        this.providers.forEach((p) => {
            if (this._providerIsIncluded(p.getType(), providers)) {
                const last = p.getLastUpdateBlock();
                if (last < 0)
                    return;
                if (lastUpdateBlock === undefined)
                    lastUpdateBlock = last;
                else
                    lastUpdateBlock = Math.min(lastUpdateBlock, last);
            }
        });
        return lastUpdateBlock === undefined ? 0 : lastUpdateBlock;
    }
    transformToken(t) {
        return t instanceof currency_1.Native ? currency_1.WNATIVE[t.chainId] : t;
    }
}
exports.DataFetcher = DataFetcher;
