"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PoolType = void 0;
var PoolType;
(function (PoolType) {
    PoolType["Standard"] = "Standard";
    PoolType["Stable"] = "Stable";
    PoolType["Concentrated"] = "Concentrated";
    PoolType["Unknown"] = "Unknown";
})(PoolType || (exports.PoolType = PoolType = {}));
