"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Limited = void 0;
function getTrottle(times, intervalMS) {
    const lastTrorrledCalls = [];
    const trottle = async () => {
        const now = Date.now();
        while (lastTrorrledCalls.length > 0) {
            const first = lastTrorrledCalls[0];
            if (now - first < intervalMS)
                break;
            lastTrorrledCalls.shift();
        }
        if (lastTrorrledCalls.length < times) {
            lastTrorrledCalls.push(now);
            return;
        }
        await new Promise(resolve => setTimeout(resolve, intervalMS + lastTrorrledCalls[0] - now + 1));
        await trottle();
    };
    return trottle;
}
class Limited {
    constructor(times, intervalMS) {
        this.trottle = getTrottle(times, intervalMS);
        this.counterTotalCall = 0;
        this.counterFailedCall = 0;
    }
    async call(func) {
        while (1) {
            this.trottle();
            ++this.counterTotalCall;
            try {
                return await func();
            }
            catch (e) {
                ++this.counterFailedCall;
            }
        }
        return await func();
    }
    async callOnce(func) {
        this.trottle();
        ++this.counterTotalCall;
        return await func();
    }
}
exports.Limited = Limited;
