"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoeV2PoolCode = void 0;
const HEXer_1 = require("../../HEXer");
const PoolCode_1 = require("./PoolCode");
class JoeV2PoolCode extends PoolCode_1.PoolCode {
    constructor(pool, providerName) {
        super(pool, providerName);
    }
    getSwapCodeForRouteProcessor(_leg, _route, _to) {
        return 'unsupported';
    }
    getSwapCodeForRouteProcessor2(leg, _route, to) {
        const code = new HEXer_1.HEXer()
            .uint8(5) // joev2 pool
            .address(this.pool.address)
            .bool(leg.tokenFrom.address === this.pool.token0.address)
            .address(to)
            .toString();
        return code;
    }
}
exports.JoeV2PoolCode = JoeV2PoolCode;
