"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArthSwapProvider = void 0;
const chain_1 = require("@zenlink-interface/chain");
const LiquidityProvider_1 = require("./LiquidityProvider");
const UniswapV2Base_1 = require("./UniswapV2Base");
class ArthSwapProvider extends UniswapV2Base_1.UniswapV2BaseProvider {
    constructor(chainId, client) {
        super(chainId, client);
        this.factory = {
            [chain_1.ParachainId.ASTAR]: '0xA9473608514457b4bF083f9045fA63ae5810A03E',
        };
        this.initCodeHash = {
            [chain_1.ParachainId.ASTAR]: '0x613b36de6401276e4d938ad0db4063490e66bb3ab2e4aec17cab78a15ea7a0b6',
        };
    }
    getType() {
        return LiquidityProvider_1.LiquidityProviders.ArthSwapV2;
    }
    getPoolProviderName() {
        return 'ArthSwapV2';
    }
}
exports.ArthSwapProvider = ArthSwapProvider;
