import * as React from 'react'

export function SubwalletIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      fill="none"
      viewBox="0 0 384 576"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_357_8054)">
        <path
          d="M384 208.319V128.389L63.9437 0L0 32.4718L0.337606 281.256L239.789 377.672L111.902 432.125V390.016L53.1885 366.182L0.337594 391.16L0.337613 543.528L64 576L384 431.625V329.214L96 213.939V144L324.5 235.296L384 208.319Z"
          fill="#004BFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_357_8054">
          <rect fill="white" height="576" width="384" />
        </clipPath>
      </defs>
    </svg>
  )
}
