"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BeamSwapV2Provider = void 0;
const chain_1 = require("@zenlink-interface/chain");
const LiquidityProvider_1 = require("./LiquidityProvider");
const UniswapV2Base_1 = require("./UniswapV2Base");
class BeamSwapV2Provider extends UniswapV2Base_1.UniswapV2BaseProvider {
    constructor(chainId, client) {
        super(chainId, client);
        this.factory = {
            [chain_1.ParachainId.MOONBEAM]: '0x985BcA32293A7A496300a48081947321177a86FD',
        };
        this.initCodeHash = {
            [chain_1.ParachainId.MOONBEAM]: '0xe31da4209ffcce713230a74b5287fa8ec84797c9e77e1f7cfeccea015cdc97ea',
        };
    }
    getType() {
        return LiquidityProvider_1.LiquidityProviders.BeamswapV2;
    }
    getPoolProviderName() {
        return 'BeamswapV2';
    }
}
exports.BeamSwapV2Provider = BeamSwapV2Provider;
