// src/Big.ts
import Big from "big.js";
import toFormat from "toformat";
Big.strict = true;
var Big_default = toFormat(Big);

// src/constants/numbers.ts
import JSBI from "jsbi";
var ZERO = JSBI.BigInt(0);
var ONE = JSBI.BigInt(1);
var TWO = JSBI.BigInt(2);
var THREE = JSBI.BigInt(3);
var FOUR = JSBI.BigInt(4);
var FIVE = JSBI.BigInt(5);
var SIX = JSBI.BigInt(6);
var SEVEN = JSBI.BigInt(7);
var EIGHT = JSBI.BigInt(8);
var NINE = JSBI.BigInt(9);
var TEN = JSBI.BigInt(10);
var ELEVEN = JSBI.BigInt(11);
var TWELVE = JSBI.BigInt(12);
var THIRTEEN = JSBI.BigInt(13);
var FOURTEEN = JSBI.BigInt(14);
var FIFTEEN = JSBI.BigInt(15);
var _100 = JSBI.BigInt(100);
var _997 = JSBI.BigInt(997);
var _999 = JSBI.BigInt(999);
var _1000 = JSBI.BigInt(1e3);
var _9994 = JSBI.BigInt(9994);
var _9995 = JSBI.BigInt(9995);
var _10000 = JSBI.BigInt(1e4);
var _1e18 = JSBI.BigInt(1e18);
var _1e15 = JSBI.BigInt(1e15);
var _1e12 = JSBI.BigInt(1e12);
var _1e9 = JSBI.BigInt(1e9);
var _1e6 = JSBI.BigInt(1e6);
var MAX_SAFE_INTEGER = JSBI.BigInt(Number.MAX_SAFE_INTEGER);
var MAX_UINT256 = JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
var MAX_UINT128 = JSBI.subtract(JSBI.exponentiate(JSBI.BigInt("2"), JSBI.BigInt(128)), JSBI.BigInt(1));

// src/Decimal.ts
import Decimal from "decimal.js-light";
import toFormat2 from "toformat";
var Decimal_default = toFormat2(Decimal);

// src/Fraction.ts
import JSBI2 from "jsbi";
import invariant from "tiny-invariant";

// src/Rounding.ts
var Rounding = /* @__PURE__ */ ((Rounding2) => {
  Rounding2[Rounding2["ROUND_DOWN"] = 0] = "ROUND_DOWN";
  Rounding2[Rounding2["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
  Rounding2[Rounding2["ROUND_UP"] = 3] = "ROUND_UP";
  return Rounding2;
})(Rounding || {});
var Rounding_default = Rounding;

// src/Fraction.ts
var toSignificantRounding = {
  [Rounding_default.ROUND_DOWN]: Decimal_default.ROUND_DOWN,
  [Rounding_default.ROUND_HALF_UP]: Decimal_default.ROUND_HALF_UP,
  [Rounding_default.ROUND_UP]: Decimal_default.ROUND_UP
};
var toFixedRounding = {
  [Rounding_default.ROUND_DOWN]: Big_default.roundDown,
  [Rounding_default.ROUND_HALF_UP]: Big_default.roundHalfUp,
  [Rounding_default.ROUND_UP]: Big_default.roundUp
};
var Fraction = class _Fraction {
  constructor(numerator, denominator = JSBI2.BigInt(1)) {
    this.numerator = JSBI2.BigInt(numerator);
    this.denominator = JSBI2.BigInt(denominator);
  }
  static tryParseFraction(fractionish) {
    if (fractionish instanceof JSBI2 || typeof fractionish === "number" || typeof fractionish === "string")
      return new _Fraction(fractionish);
    if ("numerator" in fractionish && "denominator" in fractionish)
      return fractionish;
    throw new Error("Could not parse fraction");
  }
  // performs floor division
  get quotient() {
    return JSBI2.divide(this.numerator, this.denominator);
  }
  // remainder after floor division
  get remainder() {
    return new _Fraction(JSBI2.remainder(this.numerator, this.denominator), this.denominator);
  }
  invert() {
    return new _Fraction(this.denominator, this.numerator);
  }
  add(other) {
    const otherParsed = _Fraction.tryParseFraction(other);
    if (JSBI2.equal(this.denominator, otherParsed.denominator))
      return new _Fraction(JSBI2.add(this.numerator, otherParsed.numerator), this.denominator);
    return new _Fraction(
      JSBI2.add(
        JSBI2.multiply(this.numerator, otherParsed.denominator),
        JSBI2.multiply(otherParsed.numerator, this.denominator)
      ),
      JSBI2.multiply(this.denominator, otherParsed.denominator)
    );
  }
  subtract(other) {
    const otherParsed = _Fraction.tryParseFraction(other);
    if (JSBI2.equal(this.denominator, otherParsed.denominator))
      return new _Fraction(JSBI2.subtract(this.numerator, otherParsed.numerator), this.denominator);
    return new _Fraction(
      JSBI2.subtract(
        JSBI2.multiply(this.numerator, otherParsed.denominator),
        JSBI2.multiply(otherParsed.numerator, this.denominator)
      ),
      JSBI2.multiply(this.denominator, otherParsed.denominator)
    );
  }
  lessThan(other) {
    const otherParsed = _Fraction.tryParseFraction(other);
    return JSBI2.lessThan(
      JSBI2.multiply(this.numerator, otherParsed.denominator),
      JSBI2.multiply(otherParsed.numerator, this.denominator)
    );
  }
  equalTo(other) {
    const otherParsed = _Fraction.tryParseFraction(other);
    return JSBI2.equal(
      JSBI2.multiply(this.numerator, otherParsed.denominator),
      JSBI2.multiply(otherParsed.numerator, this.denominator)
    );
  }
  greaterThan(other) {
    const otherParsed = _Fraction.tryParseFraction(other);
    return JSBI2.greaterThan(
      JSBI2.multiply(this.numerator, otherParsed.denominator),
      JSBI2.multiply(otherParsed.numerator, this.denominator)
    );
  }
  multiply(other) {
    const otherParsed = _Fraction.tryParseFraction(other);
    return new _Fraction(
      JSBI2.multiply(this.numerator, otherParsed.numerator),
      JSBI2.multiply(this.denominator, otherParsed.denominator)
    );
  }
  divide(other) {
    const otherParsed = _Fraction.tryParseFraction(other);
    return new _Fraction(
      JSBI2.multiply(this.numerator, otherParsed.denominator),
      JSBI2.multiply(this.denominator, otherParsed.numerator)
    );
  }
  toSignificant(significantDigits, format = { groupSeparator: "" }, rounding = Rounding_default.ROUND_HALF_UP) {
    invariant(Number.isInteger(significantDigits), `${significantDigits} is not an integer.`);
    invariant(significantDigits > 0, `${significantDigits} is not positive.`);
    Decimal_default.set({
      precision: significantDigits + 1,
      rounding: toSignificantRounding[rounding]
    });
    const quotient = new Decimal_default(this.numerator.toString()).div(this.denominator.toString()).toSignificantDigits(significantDigits);
    return quotient.toFormat(quotient.decimalPlaces(), format);
  }
  toFixed(decimalPlaces, format = { groupSeparator: "" }, rounding = Rounding_default.ROUND_HALF_UP) {
    invariant(Number.isInteger(decimalPlaces), `${decimalPlaces} is not an integer.`);
    invariant(decimalPlaces >= 0, `${decimalPlaces} is negative.`);
    Big_default.DP = decimalPlaces;
    Big_default.RM = toFixedRounding[rounding];
    return new Big_default(this.numerator.toString()).div(this.denominator.toString()).toFormat(decimalPlaces, format);
  }
  /**
   * Helper method for converting any super class back to a fraction
   */
  get asFraction() {
    return new _Fraction(this.numerator, this.denominator);
  }
};
var Fraction_default = Fraction;

// src/functions/difference.ts
import JSBI3 from "jsbi";
function difference(a, b) {
  if (JSBI3.greaterThan(a, b))
    return JSBI3.subtract(a, b);
  return JSBI3.subtract(b, a);
}

// src/functions/maximum.ts
import JSBI4 from "jsbi";
function maximum(...values) {
  let highest = values[0];
  for (let i = 1; i < values.length; i++) {
    const value = values[i];
    if (JSBI4.GT(value, highest))
      highest = value;
  }
  return highest;
}

// src/functions/minimum.ts
import JSBI5 from "jsbi";
function minimum(...values) {
  let lowest = values[0];
  for (let i = 1; i < values.length; i++) {
    const value = values[i];
    if (JSBI5.LT(value, lowest))
      lowest = value;
  }
  return lowest;
}

// src/functions/sqrt.ts
import JSBI6 from "jsbi";
import invariant2 from "tiny-invariant";
function sqrt(value) {
  invariant2(JSBI6.greaterThanOrEqual(value, ZERO), "NEGATIVE");
  if (JSBI6.lessThan(value, MAX_SAFE_INTEGER))
    return JSBI6.BigInt(Math.floor(Math.sqrt(JSBI6.toNumber(value))));
  let z;
  let x;
  z = value;
  x = JSBI6.add(JSBI6.divide(value, TWO), ONE);
  while (JSBI6.lessThan(x, z)) {
    z = x;
    x = JSBI6.divide(JSBI6.add(JSBI6.divide(value, x), x), TWO);
  }
  return z;
}

// src/functions/uuid.ts
function uuid() {
  const hashTable = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9"
  ];
  const uuid2 = [];
  for (let i = 0; i < 36; i++) {
    if (i === 8 || i === 13 || i === 18 || i === 23)
      uuid2[i] = "-";
    else
      uuid2[i] = hashTable[Math.ceil(Math.random() * hashTable.length - 1)];
  }
  return uuid2.join("");
}

// src/Percent.ts
import JSBI7 from "jsbi";
var ONE_HUNDRED = new Fraction_default(JSBI7.BigInt(100));
function toPercent(fraction) {
  return new Percent(fraction.numerator, fraction.denominator);
}
var Percent = class extends Fraction_default {
  constructor() {
    super(...arguments);
    /**
     * This boolean prevents a fraction from being interpreted as a Percent
     */
    this.isPercent = true;
  }
  add(other) {
    return toPercent(super.add(other));
  }
  subtract(other) {
    return toPercent(super.subtract(other));
  }
  multiply(other) {
    return toPercent(super.multiply(other));
  }
  divide(other) {
    return toPercent(super.divide(other));
  }
  toSignificant(significantDigits = 5, format, rounding) {
    return super.multiply(ONE_HUNDRED).toSignificant(significantDigits, format, rounding);
  }
  toFixed(decimalPlaces = 2, format, rounding) {
    return super.multiply(ONE_HUNDRED).toFixed(decimalPlaces, format, rounding);
  }
};
var Percent_default = Percent;

// src/index.ts
import { default as default2 } from "jsbi";
export {
  Big_default as Big,
  Decimal_default as Decimal,
  EIGHT,
  ELEVEN,
  FIFTEEN,
  FIVE,
  FOUR,
  FOURTEEN,
  Fraction_default as Fraction,
  default2 as JSBI,
  MAX_SAFE_INTEGER,
  MAX_UINT128,
  MAX_UINT256,
  NINE,
  ONE,
  Percent_default as Percent,
  Rounding_default as Rounding,
  SEVEN,
  SIX,
  TEN,
  THIRTEEN,
  THREE,
  TWELVE,
  TWO,
  ZERO,
  _100,
  _1000,
  _10000,
  _1e12,
  _1e15,
  _1e18,
  _1e6,
  _1e9,
  _997,
  _999,
  _9994,
  _9995,
  difference,
  maximum,
  minimum,
  sqrt,
  uuid
};
