"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findLeftFromCursor = findLeftFromCursor;
exports.findRightFromCursor = findRightFromCursor;
exports.nearestLeftOneOrBoundary = nearestLeftOneOrBoundary;
exports.nearestRightOneOrBoundary = nearestRightOneOrBoundary;
const _1 = require(".");
function findLeftFromCursor(orders, currentCursor, currentPoint) {
    let liquidityIdx = currentCursor.liquidityIdx;
    while (liquidityIdx >= 0 && orders.liquidityDeltaPoint[liquidityIdx] > currentPoint)
        --liquidityIdx;
    let sellingIdx = currentCursor.sellingIdx;
    while (sellingIdx >= 0 && orders.sellingYPoint[sellingIdx] > currentPoint)
        --sellingIdx;
    const isLiquidityPoint = (liquidityIdx >= 0) && (orders.liquidityDeltaPoint[liquidityIdx] === currentPoint);
    const isLimitOrderPoint = (sellingIdx >= 0) && (orders.sellingYPoint[sellingIdx] === currentPoint);
    return { liquidityIdx, sellingIdx, currentPoint, isLimitOrderPoint, isLiquidityPoint };
}
function findRightFromCursor(orders, currentCursor, currentPoint) {
    let liquidityIdx = currentCursor.liquidityIdx;
    const liquidityLength = orders.liquidity.length;
    while (liquidityIdx < liquidityLength && orders.liquidityDeltaPoint[liquidityIdx] <= currentPoint)
        ++liquidityIdx;
    liquidityIdx--;
    let sellingIdx = currentCursor.sellingIdx;
    const sellingLength = orders.sellingX.length;
    while (sellingIdx < sellingLength && orders.sellingXPoint[sellingIdx] < currentPoint)
        ++sellingIdx;
    const isLiquidityPoint = (liquidityIdx >= 0) && (orders.liquidityDeltaPoint[liquidityIdx] === currentPoint);
    const isLimitOrderPoint = (sellingIdx < sellingLength) && (orders.sellingXPoint[sellingIdx] === currentPoint);
    return { liquidityIdx, sellingIdx, currentPoint, isLimitOrderPoint, isLiquidityPoint };
}
function nearestLeftOneOrBoundary(orders, currentCursor, point, pointDelta) {
    const liquidityPoint = currentCursor.liquidityIdx >= 0 ? orders.liquidityDeltaPoint[currentCursor.liquidityIdx] : _1.IZI_LEFT_MOST_PT;
    const sellingPoint = currentCursor.sellingIdx >= 0 ? orders.sellingYPoint[currentCursor.sellingIdx] : _1.IZI_LEFT_MOST_PT;
    const nextPoint = Math.max(liquidityPoint, sellingPoint);
    const mapPt = Math.floor(point / pointDelta);
    const bitIdx = (mapPt % 256 + 256) % 256;
    const leftPtInBlock = (mapPt - bitIdx) * pointDelta;
    return Math.max(nextPoint, leftPtInBlock);
}
function nearestRightOneOrBoundary(orders, currentCursor, point, pointDelta) {
    const liquidityLength = orders.liquidity.length;
    const destLiquidityIdx = currentCursor.liquidityIdx + 1;
    const liquidityPoint = destLiquidityIdx < liquidityLength ? orders.liquidityDeltaPoint[destLiquidityIdx] : _1.IZI_RIGHT_MOST_PT;
    const destSellingIdx = currentCursor.isLimitOrderPoint ? currentCursor.sellingIdx + 1 : currentCursor.sellingIdx;
    const sellingPoint = destSellingIdx < orders.sellingX.length ? orders.sellingXPoint[destSellingIdx] : _1.IZI_RIGHT_MOST_PT;
    const nextPoint = Math.min(liquidityPoint, sellingPoint);
    let mapPt = Math.floor(point / pointDelta);
    // strict right of current point
    mapPt += 1;
    const bitIdx = (mapPt % 256 + 256) % 256;
    const rightPtInBlock = (mapPt + 255 - bitIdx) * pointDelta;
    return Math.min(nextPoint, rightPtInBlock);
}
