"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.curveStableBase = void 0;
exports.curveStableBase = [
    {
        gas: 5227,
        inputs: [],
        name: 'A',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        gas: 1133640,
        inputs: [],
        name: 'get_virtual_price',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        gas: 2220,
        inputs: [
            {
                internalType: 'uint256',
                name: 'arg0',
                type: 'uint256',
            },
        ],
        name: 'coins',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        gas: 2250,
        inputs: [
            {
                internalType: 'uint256',
                name: 'arg0',
                type: 'uint256',
            },
        ],
        name: 'balances',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        gas: 2171,
        inputs: [],
        name: 'fee',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        gas: 2201,
        inputs: [],
        name: 'admin_fee',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
];
