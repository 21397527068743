"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteProcessor2 = void 0;
exports.getRouteProcessor2Code = getRouteProcessor2Code;
const amm_1 = require("@zenlink-interface/amm");
const entities_1 = require("../entities");
const HEXer_1 = require("../HEXer");
class RouteProcessor2 {
    constructor(routeProcessorAddress, feeSettlementAddress, chainId, pools) {
        this.routeProcessorAddress = routeProcessorAddress;
        this.feeSettlementAddress = feeSettlementAddress;
        this.chainId = chainId;
        this.pools = pools;
        this.tokenOutputLegs = new Map();
    }
    getRouteCode(route) {
        // 0. Check for no route
        if (route.status === amm_1.RouteStatus.NoWay || !route.legs.length)
            return '';
        this.calcTokenOutputLegs(route);
        let res = '0x';
        const processedTokens = new Set();
        route.legs.forEach((l, i) => {
            const token = l.tokenFrom;
            if (processedTokens.has(token.tokenId))
                return;
            processedTokens.add(token.tokenId);
            if (i > 0) {
                if (token.address === '')
                    throw new Error(`unexpected native inside the route: ${token.symbol}`);
                if (this.isOnePoolOptimization(token, route))
                    res += this.processOnePoolCode(token, route);
                else
                    res += this.processERC20Code(true, token, route);
            }
            else {
                if (token.address === '')
                    res += this.processNativeCode(token, route);
                else
                    res += this.processERC20Code(false, token, route);
            }
        });
        return res;
    }
    processNativeCode(token, route) {
        const outputLegs = this.tokenOutputLegs.get(token.tokenId);
        if (!outputLegs || outputLegs.length !== 1)
            throw new Error(`Not 1 output pool for native token: ${outputLegs?.length}`);
        const hex = new HEXer_1.HEXer()
            .uint8(3) // processNative commandCode
            .uint8(outputLegs.length);
        outputLegs.forEach((l) => {
            hex.share16(l.swapPortion).hexData(this.swapCode(l, route));
        });
        return hex.toString();
    }
    processERC20Code(fromMy, token, route) {
        const outputLegs = this.tokenOutputLegs.get(token.tokenId);
        if (!outputLegs || !outputLegs.length)
            throw new Error(`No output legs for token ${token.symbol}`);
        const hex = new HEXer_1.HEXer()
            .uint8(fromMy ? 1 : 2) // processMyERC20 : processUserERC20 commandCode
            .address(token.address)
            .uint8(outputLegs.length);
        outputLegs.forEach((l) => {
            hex.share16(l.swapPortion).hexData(this.swapCode(l, route));
        });
        return hex.toString();
    }
    processOnePoolCode(token, route) {
        const outputLegs = this.tokenOutputLegs.get(token.tokenId);
        if (!outputLegs || outputLegs.length !== 1)
            throw new Error(`1 output leg expected ${outputLegs?.length}`);
        const hex = new HEXer_1.HEXer()
            .uint8(4) // processOnePool commandCode
            .address(token.address)
            .hexData(this.swapCode(outputLegs[0], route));
        return hex.toString();
    }
    swapCode(leg, route) {
        const pc = this.getPoolCode(leg);
        const to = this.getPoolOutputAddress(leg, route);
        return pc.getSwapCodeForRouteProcessor2(leg, route, to); // , this.presendedLegs.has(leg))
    }
    getPoolOutputAddress(l, route) {
        let outAddress;
        const outputDistribution = this.tokenOutputLegs.get(l.tokenTo.tokenId) || [];
        if (!outputDistribution.length) {
            outAddress = this.feeSettlementAddress;
        }
        else if (outputDistribution.length === 1) {
            outAddress = this.getPoolCode(outputDistribution[0]).getStartPoint(l, route);
            if (outAddress === entities_1.PoolCode.RouteProcessorAddress)
                outAddress = this.routeProcessorAddress;
        }
        else {
            outAddress = this.routeProcessorAddress;
        }
        return outAddress;
    }
    isOnePoolOptimization(token, route) {
        const outputDistribution = this.tokenOutputLegs.get(token.tokenId) || [];
        if (outputDistribution.length !== 1)
            return false;
        const startPoint = this.getPoolCode(outputDistribution[0]).getStartPoint(outputDistribution[0], route);
        return startPoint === outputDistribution[0].poolAddress;
    }
    getPoolCode(l) {
        const pc = this.pools.get(l.poolId);
        if (pc === undefined)
            throw new Error(`unknown pool: ${l.poolId}`);
        return pc;
    }
    calcTokenOutputLegs(route) {
        const res = new Map();
        route.legs.forEach((l) => {
            const tokenId = l.tokenFrom.tokenId?.toString();
            if (tokenId === undefined) {
                throw new Error('Unseted tokenId');
            }
            else {
                const legsOutput = res.get(tokenId) || [];
                legsOutput.push(l);
                res.set(tokenId, legsOutput);
            }
        });
        this.tokenOutputLegs = res;
    }
}
exports.RouteProcessor2 = RouteProcessor2;
function getRouteProcessor2Code(route, routeProcessorAddress, feeSettlementAddress, pools) {
    const rp = new RouteProcessor2(routeProcessorAddress, feeSettlementAddress, route.fromToken.chainId, pools);
    return rp.getRouteCode(route);
}
