"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NativeWrapPoolCode = void 0;
const CommandCode_1 = require("../../CommandCode");
const HEXer_1 = require("../../HEXer");
const PoolCode_1 = require("./PoolCode");
class NativeWrapPoolCode extends PoolCode_1.PoolCode {
    constructor(pool) {
        super(pool, 'Wrap');
    }
    getStartPoint() {
        return PoolCode_1.PoolCode.RouteProcessorAddress;
    }
    getProtocolExecutorStartPoint() {
        return PoolCode_1.PoolCode.ProtocolExecutorAddress;
    }
    getSwapCodeForRouteProcessor(leg, _route, to) {
        if (leg.tokenFrom.tokenId === this.pool.token0.tokenId) {
            // wrap - deposit. not used normally
            // wrapAndDistributeERC20Amounts
            const code = new HEXer_1.HEXer()
                .uint8(CommandCode_1.CommandCode.WRAP_AND_DISTRIBUTE_ERC20_AMOUNTS)
                .address(this.pool.address)
                .uint8(0)
                .toString();
            return code;
        }
        else {
            // unwrap - withdraw
            // unwrapNative(address receiver, unwrap token)
            const code = new HEXer_1.HEXer()
                .uint8(CommandCode_1.CommandCode.UNWRAP_NATIVE)
                .address(this.pool.address)
                .address(to)
                .toString();
            return code;
        }
    }
    getSwapCodeForRouteProcessor2(leg, _route, to) {
        if (leg.tokenFrom.tokenId === this.pool.token0.tokenId) {
            // wrap - deposit
            const code = new HEXer_1.HEXer()
                .uint8(2) // wrapNative pool type
                .uint8(1) // wrap action
                .address(to) // where to transfer native coin after unwrapping
                .address(this.pool.address) // wrap token
                .toString();
            return code;
        }
        else {
            // unwrap - withdraw
            const code = new HEXer_1.HEXer()
                .uint8(2) // wrapNative pool type
                .uint8(0) // unwrap action
                .address(to) // where to transfer native coin after unwrapping
                .toString();
            return code;
        }
    }
    getSwapCodeForAggregationRouter(leg, _route, to) {
        if (leg.tokenFrom.tokenId === this.pool.token0.tokenId) {
            // wrap - deposit
            const code = new HEXer_1.HEXer()
                .uint8(1) // wrap action
                .address(to) // where to transfer native coin after unwrapping
                .address(this.pool.address) // wrap token
                .toString();
            return code;
        }
        else {
            // unwrap - withdraw
            const code = new HEXer_1.HEXer()
                .uint8(0) // unwrap action
                .address(to) // where to transfer native coin after unwrapping
                .toString();
            return code;
        }
    }
}
exports.NativeWrapPoolCode = NativeWrapPoolCode;
