"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STABLE_LP_OVERRIDE = exports.STABLE_POOL_ADDRESS = void 0;
const chain_1 = require("@zenlink-interface/chain");
exports.STABLE_POOL_ADDRESS = {
    [chain_1.ParachainId.MOONBEAM]: [
        '0x68bed2c54Fd0e6Eeb70cFA05723EAE7c06805EC5', // 4pool
    ],
    [chain_1.ParachainId.ASTAR]: [
        '0xb0Fa056fFFb74c0FB215F86D691c94Ed45b686Aa', // 4pool
    ],
    [chain_1.ParachainId.MOONRIVER]: [
        '0x7BDE79AD4ae9023AC771F435A1DC6efdF3F434D1', // 4pool
    ],
};
exports.STABLE_LP_OVERRIDE = {
    [chain_1.ParachainId.MOONBEAM]: {
        '0xF3821FD2d235eC6C9DB633947A89A16e11a9c1A9': {
            symbol: '4pool',
            name: 'madUSDT/madUSDC/xcaUSD/FRAX',
        },
    },
    [chain_1.ParachainId.ASTAR]: {
        '0x755cbAC2246e8219e720591Dd362a772076ab653': {
            symbol: '4pool',
            name: 'USDC/BUSD/BAI/DAI',
        },
    },
    [chain_1.ParachainId.MOONRIVER]: {
        '0x569B9459Db9bcd5964f0c9BdBAA2416722A4bD1a': {
            symbol: '4pool',
            name: 'USDT/USDC/xcAUSD/FRAX',
        },
    },
};
