"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NatvieWrapPool = void 0;
const bignumber_1 = require("@ethersproject/bignumber");
const BasePool_1 = require("./BasePool");
class NatvieWrapPool extends BasePool_1.BasePool {
    constructor(address, token0, token1, fee, swapGasCost = 150_000) {
        super(address, token0, token1, fee, bignumber_1.BigNumber.from(-1), bignumber_1.BigNumber.from(-1), 0, swapGasCost);
    }
    getOutput(amountIn, _direction) {
        return { output: amountIn * (1 - this.fee), gasSpent: this.swapGasCost };
    }
    getInput(amountOut, _direction) {
        return { input: amountOut / (1 - this.fee), gasSpent: this.swapGasCost };
    }
    calcCurrentPriceWithoutFee(_direction) {
        return 1;
    }
    alwaysAppropriateForPricing() {
        return true;
    }
}
exports.NatvieWrapPool = NatvieWrapPool;
