import React from 'react'

export function ImTokenIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg height="512" viewBox="0 0 513 512" width="513" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_88_1636)">
        <circle cx="256.319" cy="256" fill="url(#paint0_linear_88_1636)" r="256" />
        <path d="M417.435 158.291C428.083 302.507 335.381 370.67 252.276 377.939C175.013 384.696 102.286 337.221 95.906 264.279C90.6429 204.017 127.888 178.361 157.151 175.804C187.248 173.165 212.541 193.921 214.735 219.052C216.848 243.213 201.772 254.211 191.287 255.126C182.995 255.853 172.563 250.819 171.621 240.01C170.813 230.722 174.34 229.457 173.478 219.59C171.944 202.024 156.626 199.978 148.24 200.705C138.091 201.594 119.677 213.439 122.261 242.944C124.859 272.705 153.395 296.221 190.802 292.95C231.17 289.423 259.275 257.993 261.389 213.91C261.369 211.575 261.861 209.264 262.829 207.139L262.842 207.086C263.277 206.162 263.786 205.275 264.363 204.434C265.225 203.142 266.329 201.715 267.755 200.153C267.769 200.113 267.769 200.113 267.796 200.113C268.832 198.942 270.084 197.677 271.497 196.317C289.131 179.68 352.637 140.443 412.697 152.867C413.967 153.139 415.114 153.814 415.968 154.792C416.822 155.77 417.336 156.997 417.435 158.291" fill="white" />
      </g>
      <defs>
        <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_88_1636" x1="498.003" x2="0.318848" y1="14.3155" y2="512">
          <stop stopColor="#11C4D1" />
          <stop offset="1" stopColor="#0062AD" />
        </linearGradient>
        <clipPath id="clip0_88_1636">
          <rect fill="white" height="512" transform="translate(0.318848)" width="512" />
        </clipPath>
      </defs>
    </svg>
  )
}
