"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WrappedTokenInfo = void 0;
const address_1 = require("@ethersproject/address");
const format_1 = require("@zenlink-interface/format");
const math_1 = require("@zenlink-interface/math");
__exportStar(require("./lists"), exports);
__exportStar(require("./types"), exports);
/**
 * Token instances created from token info on a token list.
 */
class WrappedTokenInfo {
    constructor(tokenInfo, list) {
        this.isNative = false;
        this.isToken = true;
        this.rebase = { base: math_1.JSBI.BigInt(1), elastic: math_1.JSBI.BigInt(1) };
        this._checksummedAddress = null;
        this._tags = null;
        this.tokenInfo = tokenInfo;
        this.list = list;
    }
    get address() {
        if (this._checksummedAddress)
            return this._checksummedAddress;
        if (!(0, address_1.isAddress)(this.tokenInfo.address))
            return `${this.tokenInfo.parachainId}-${this.tokenInfo.assetType}-${this.tokenInfo.assetIndex}`;
        const checksummedAddress = (0, address_1.getAddress)(this.tokenInfo.address);
        return (this._checksummedAddress = checksummedAddress);
    }
    get chainId() {
        return this.tokenInfo.parachainId;
    }
    get decimals() {
        return this.tokenInfo.decimals;
    }
    get name() {
        return this.tokenInfo.name;
    }
    get symbol() {
        return this.tokenInfo.symbol;
    }
    get logoURI() {
        return this.tokenInfo.logoURI;
    }
    get tags() {
        if (this._tags !== null)
            return this._tags;
        if (!this.tokenInfo.tags)
            return (this._tags = []);
        const listTags = this.list?.tags;
        if (!listTags)
            return (this._tags = []);
        return (this._tags = this.tokenInfo.tags.map((tagId) => {
            return {
                ...listTags[tagId],
                id: tagId,
            };
        }));
    }
    equals(other) {
        return other.chainId === this.chainId && other.isToken && other.address.toLowerCase() === this.address.toLowerCase();
    }
    sortsBefore(other) {
        if (this.equals(other))
            throw new Error('Addresses should not be equal');
        if (!(0, address_1.isAddress)(this.address) && (0, format_1.isZenlinkAddress)(this.address)) {
            const { chainId, assetType, assetIndex } = (0, format_1.addressToZenlinkAssetId)(this.address);
            const otherTokenAssetId = (0, format_1.addressToZenlinkAssetId)(other.address);
            return (chainId < otherTokenAssetId.chainId
                || assetType < otherTokenAssetId.assetType
                || assetIndex < otherTokenAssetId.assetIndex);
        }
        return this.address.toLowerCase() < other.address.toLowerCase();
    }
    get wrapped() {
        return this;
    }
}
exports.WrappedTokenInfo = WrappedTokenInfo;
