"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreferrableLiquidityProviders = void 0;
exports.findSpecialRoute = findSpecialRoute;
const amm_1 = require("@zenlink-interface/amm");
const liquidity_providers_1 = require("../liquidity-providers");
const Router_1 = require("./Router");
exports.PreferrableLiquidityProviders = [
    liquidity_providers_1.LiquidityProviders.Zenlink,
    liquidity_providers_1.LiquidityProviders.ArthSwapV2,
];
function findSpecialRoute(dataFetcher, fromToken, amountIn, toToken, gasPrice, maxPriceImpact = 1) {
    // Find preferrable route
    const preferrableRoute = Router_1.Router.findBestRoute(dataFetcher, fromToken, amountIn, toToken, gasPrice, exports.PreferrableLiquidityProviders);
    // If the route is successful and the price impact is less than maxPriceImpact, then return the route
    if (preferrableRoute.status === amm_1.RouteStatus.Success
        && preferrableRoute.priceImpact !== undefined
        && preferrableRoute.priceImpact < maxPriceImpact / 100) {
        return preferrableRoute;
    }
    // Otherwise, find the route using all possible liquidity providers
    return Router_1.Router.findBestRoute(dataFetcher, fromToken, amountIn, toToken, gasPrice);
}
