"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.saddleBase = void 0;
exports.saddleBase = [
    {
        inputs: [
            {
                internalType: 'uint8',
                name: 'index',
                type: 'uint8',
            },
        ],
        name: 'getTokenBalance',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'swapStorage',
        outputs: [
            {
                internalType: 'uint256',
                name: 'initialA',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'futureA',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'initialATime',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'futureATime',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'swapFee',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'adminFee',
                type: 'uint256',
            },
            {
                internalType: 'contract LPToken',
                name: 'lpToken',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getA',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getVirtualPrice',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'totalSupply',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
];
