import * as React from 'react'

export const ScrollNaked = (props: React.ComponentProps<'svg'>) => (
  <svg
    viewBox="0 0 129 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M98.2279 78.08V32.2133C98.1425 28.3733 95.1132 25.3013 91.2732 25.3013H43.4012C33.0759 25.472 24.7559 33.92 24.7559 44.288C24.7559 47.7867 25.6945 50.7733 27.1452 53.4187C28.3399 55.5947 30.2599 57.6427 32.1372 59.2213C32.6492 59.648 32.3932 59.4773 34.0145 60.5013C36.2759 61.8667 38.8359 62.5493 38.8359 62.5493V89.984C38.8785 91.264 39.0492 92.544 39.3479 93.696C40.4145 97.536 43.1025 100.523 46.7292 101.973C48.2652 102.571 49.9719 102.997 51.8065 102.997L90.0359 103.125C97.6732 103.125 103.86 96.9387 103.86 89.3013C103.86 84.6507 101.641 80.5973 98.2279 78.08Z"
        fill="#FFEEDA"
      />
      <path
        d="M99.1243 89.5147C98.9536 94.4214 94.9429 98.3467 90.0363 98.3467L63.7109 98.2614C65.8016 95.8294 67.0816 92.672 67.0816 89.2587C67.0816 83.84 63.8389 80.1281 63.8389 80.1281H90.0363C95.0709 80.1281 99.1243 84.224 99.1243 89.2587V89.5147Z"
        fill="#EBC28E"
      />
      <path
        d="M34.5694 55.2533C31.54 52.3947 29.4067 48.6827 29.4067 44.2453V43.776C29.6627 36.2667 35.8494 30.208 43.3587 29.952H91.2307C92.468 29.9947 93.492 30.8907 93.492 32.1707V72.6613C94.6014 72.8747 95.1134 73.0027 96.18 73.3867C96.9907 73.6853 98.1854 74.368 98.1854 74.368V32.1707C98.1 28.3307 95.0707 25.2587 91.2307 25.2587H43.3587C33.0334 25.4293 24.7134 33.8773 24.7134 44.2453C24.7134 50.2613 27.444 55.424 31.9667 59.0507C32.2654 59.3067 32.564 59.6053 33.3747 59.6053C34.7827 59.6053 35.764 58.496 35.7214 57.2587C35.6787 56.2347 35.252 55.8507 34.5694 55.2533Z"
        fill="#190602"
      />
      <path
        d="M90.036 75.392H52.4894C49.972 75.392 47.924 77.4827 47.924 80V85.376C48.0093 87.8507 50.1427 90.0267 52.6173 90.0267H55.3907V85.376H52.6173V80.0853C52.6173 80.0853 53.3 80.0853 54.1534 80.0853C58.8894 80.0853 62.388 84.48 62.388 89.216C62.388 93.3973 58.5907 98.7307 52.2334 98.304C46.5587 97.92 43.5294 92.8853 43.5294 89.216V43.4347C43.5294 41.3867 41.8654 39.68 39.7747 39.68H36.02V44.3733H38.7934V89.216C38.6654 98.3467 45.2787 102.955 52.1907 102.955L90.036 103.083C97.6733 103.083 103.86 96.896 103.86 89.2587C103.86 81.6213 97.6733 75.392 90.036 75.392ZM99.124 89.5147C98.9533 94.4213 94.9427 98.3467 90.036 98.3467L63.7107 98.2613C65.8013 95.8293 67.0814 92.672 67.0814 89.2587C67.0814 83.84 63.8387 80.128 63.8387 80.128H90.036C95.0707 80.128 99.124 84.224 99.124 89.2587V89.5147Z"
        fill="#190602"
      />
      <path
        d="M80.2229 45.184H51.6362V40.5333H80.2229C81.3749 40.5333 82.2709 41.472 82.2709 42.5813V43.136C82.2709 44.288 81.3322 45.184 80.2229 45.184Z"
        fill="#190602"
      />
      <path
        d="M80.2229 67.2427H51.6362V62.592H80.2229C81.3749 62.592 82.2709 63.5306 82.2709 64.64V65.1946C82.2709 66.304 81.3322 67.2427 80.2229 67.2427Z"
        fill="#190602"
      />
      <path
        d="M85.1719 56.2347H51.5933V51.584H85.1719C86.3239 51.584 87.2199 52.5226 87.2199 53.632V54.1867C87.2626 55.296 86.3239 56.2347 85.1719 56.2347Z"
        fill="#190602"
      />
    </g>
  </svg>
)
