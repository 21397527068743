// src/appolo/client.ts
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ParachainId } from "@zenlink-interface/chain";

// src/appolo/link.ts
import { createHttpLink, split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { ARCHIVE_HOST, LEGACY_SQUID_HOST, MARKET_SQUID_HOST } from "@zenlink-interface/graph-config";
import { Kind, OperationTypeNode } from "graphql";
function createSplitLink(host) {
  const httpLink = host ? createHttpLink({ uri: host }) : void 0;
  return httpLink ? split(
    ({ query }) => {
      const def = getMainDefinition(query);
      return def.kind === Kind.OPERATION_DEFINITION && def.operation === OperationTypeNode.QUERY;
    },
    httpLink
  ) : void 0;
}
function createLegacyLink(chainId, option = { useArchive: false }) {
  return createSplitLink(
    option.useArchive ? ARCHIVE_HOST[chainId] : LEGACY_SQUID_HOST[chainId]
  );
}
function createMarketLink(chainId) {
  return createSplitLink(MARKET_SQUID_HOST[chainId]);
}

// src/appolo/client.ts
var DEFAULT_CLIENT_OPTIONS = {
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache"
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all"
    }
  }
};
var LEGACY_CLIENTS = {
  [ParachainId.MOONRIVER]: new ApolloClient({
    ...DEFAULT_CLIENT_OPTIONS,
    link: createLegacyLink(ParachainId.MOONRIVER),
    cache: new InMemoryCache()
  }),
  [ParachainId.MOONBEAM]: new ApolloClient({
    ...DEFAULT_CLIENT_OPTIONS,
    link: createLegacyLink(ParachainId.MOONBEAM),
    cache: new InMemoryCache()
  }),
  [ParachainId.ASTAR]: new ApolloClient({
    ...DEFAULT_CLIENT_OPTIONS,
    link: createLegacyLink(ParachainId.ASTAR),
    cache: new InMemoryCache()
  }),
  [ParachainId.BIFROST_KUSAMA]: new ApolloClient({
    ...DEFAULT_CLIENT_OPTIONS,
    link: createLegacyLink(ParachainId.BIFROST_KUSAMA),
    cache: new InMemoryCache()
  }),
  [ParachainId.BIFROST_POLKADOT]: new ApolloClient({
    ...DEFAULT_CLIENT_OPTIONS,
    link: createLegacyLink(ParachainId.BIFROST_POLKADOT),
    cache: new InMemoryCache()
  }),
  [ParachainId.ARBITRUM_ONE]: new ApolloClient({
    ...DEFAULT_CLIENT_OPTIONS,
    link: createLegacyLink(ParachainId.ARBITRUM_ONE),
    cache: new InMemoryCache()
  }),
  [ParachainId.BASE]: new ApolloClient({
    ...DEFAULT_CLIENT_OPTIONS,
    link: createLegacyLink(ParachainId.BASE),
    cache: new InMemoryCache()
  }),
  [ParachainId.AMPLITUDE]: new ApolloClient({
    ...DEFAULT_CLIENT_OPTIONS,
    link: createLegacyLink(ParachainId.AMPLITUDE),
    cache: new InMemoryCache()
  }),
  [ParachainId.PENDULUM]: new ApolloClient({
    ...DEFAULT_CLIENT_OPTIONS,
    link: createLegacyLink(ParachainId.PENDULUM),
    cache: new InMemoryCache()
  })
};
var ARCHIVE_CLIENTS = {
  [ParachainId.BIFROST_KUSAMA]: new ApolloClient({
    ...DEFAULT_CLIENT_OPTIONS,
    link: createLegacyLink(ParachainId.BIFROST_KUSAMA, { useArchive: true }),
    cache: new InMemoryCache()
  }),
  [ParachainId.BIFROST_POLKADOT]: new ApolloClient({
    ...DEFAULT_CLIENT_OPTIONS,
    link: createLegacyLink(ParachainId.BIFROST_POLKADOT, { useArchive: true }),
    cache: new InMemoryCache()
  }),
  [ParachainId.AMPLITUDE]: new ApolloClient({
    ...DEFAULT_CLIENT_OPTIONS,
    link: createLegacyLink(ParachainId.AMPLITUDE, { useArchive: true }),
    cache: new InMemoryCache()
  }),
  [ParachainId.PENDULUM]: new ApolloClient({
    ...DEFAULT_CLIENT_OPTIONS,
    link: createLegacyLink(ParachainId.PENDULUM, { useArchive: true }),
    cache: new InMemoryCache()
  })
};
var MARKET_CLIENTS = {
  [ParachainId.MOONBEAM]: new ApolloClient({
    ...DEFAULT_CLIENT_OPTIONS,
    link: createMarketLink(ParachainId.MOONBEAM),
    cache: new InMemoryCache()
  })
};

// src/queries/daySnapshots.ts
import { gql as gql2 } from "@apollo/client";

// src/__generated__/types-and-hooks.ts
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
var DaySnapshotsDocument = gql`
    query daySnapshots($limit: Int, $orderBy: [ZenlinkDayInfoOrderByInput!]) {
  zenlinkDayInfos(orderBy: $orderBy, limit: $limit) {
    dailyVolumeUSD
    tvlUSD
    date
  }
}
    `;
var PairByIdDocument = gql`
    query pairById($id: String!, $hourDataOrderBy: [PairHourDataOrderByInput!], $hourDataLimit: Int, $dayDataOrderBy: [PairDayDataOrderByInput!], $dayDataLimit: Int) {
  pairById(id: $id) {
    token0 {
      id
      name
      decimals
      symbol
    }
    token1 {
      id
      name
      decimals
      symbol
    }
    id
    totalSupply
    reserve0
    reserve1
    reserveUSD
    farm {
      id
      pid
      incentives {
        id
        rewardPerDay
        rewardToken {
          id
          name
          decimals
          symbol
        }
      }
      stakeApr
    }
    pairHourData(orderBy: $hourDataOrderBy, limit: $hourDataLimit) {
      id
      hourlyVolumeUSD
      reserveUSD
      hourStartUnix
    }
    pairDayData(orderBy: $dayDataOrderBy, limit: $dayDataLimit) {
      id
      dailyVolumeUSD
      reserveUSD
      date
    }
  }
}
    `;
var PairsDocument = gql`
    query pairs($limit: Int, $orderBy: [PairOrderByInput!], $hourDataOrderBy: [PairHourDataOrderByInput!], $hourDataLimit: Int, $dayDataOrderBy: [PairDayDataOrderByInput!], $dayDataLimit: Int) {
  pairs(limit: $limit, orderBy: $orderBy) {
    token0 {
      id
      name
      decimals
      symbol
    }
    token1 {
      id
      name
      decimals
      symbol
    }
    id
    totalSupply
    reserve0
    reserve1
    reserveUSD
    farm {
      id
      pid
      incentives {
        id
        rewardPerDay
        rewardToken {
          id
          name
          decimals
          symbol
        }
      }
      stakeApr
    }
    pairHourData(orderBy: $hourDataOrderBy, limit: $hourDataLimit) {
      id
      hourlyVolumeUSD
      reserveUSD
      hourStartUnix
    }
    pairDayData(orderBy: $dayDataOrderBy, limit: $dayDataLimit) {
      id
      dailyVolumeUSD
      reserveUSD
      date
    }
  }
}
    `;
var SingleTokenLockByIdDocument = gql`
    query singleTokenLockById($id: String!, $hourDataOrderBy: [SingleTokenLockHourDataOrderByInput!], $hourDataLimit: Int, $dayDataOrderBy: [SingleTokenLockDayDataOrderByInput!], $dayDataLimit: Int) {
  singleTokenLockById(id: $id) {
    token {
      id
      name
      decimals
      symbol
    }
    id
    totalLiquidity
    totalLiquidityETH
    totalLiquidityUSD
    farm {
      id
      pid
      incentives {
        id
        rewardPerDay
        rewardToken {
          id
          name
          decimals
          symbol
        }
      }
      stakeApr
    }
    singleTokenLockHourData(orderBy: $hourDataOrderBy, limit: $hourDataLimit) {
      id
      totalLiquidity
      totalLiquidityUSD
      totalLiquidityETH
      hourStartUnix
    }
    singleTokenLockDayData(orderBy: $dayDataOrderBy, limit: $dayDataLimit) {
      id
      totalLiquidity
      totalLiquidityUSD
      totalLiquidityETH
      date
    }
  }
}
    `;
var SingleTokenLocksDocument = gql`
    query singleTokenLocks($limit: Int, $orderBy: [SingleTokenLockOrderByInput!], $hourDataOrderBy: [SingleTokenLockHourDataOrderByInput!], $hourDataLimit: Int, $dayDataOrderBy: [SingleTokenLockDayDataOrderByInput!], $dayDataLimit: Int) {
  singleTokenLocks(limit: $limit, orderBy: $orderBy) {
    token {
      id
      name
      decimals
      symbol
    }
    id
    totalLiquidity
    totalLiquidityETH
    totalLiquidityUSD
    farm {
      id
      pid
      incentives {
        id
        rewardPerDay
        rewardToken {
          symbol
        }
      }
      stakeApr
    }
    singleTokenLockHourData(orderBy: $hourDataOrderBy, limit: $hourDataLimit) {
      id
      totalLiquidity
      totalLiquidityUSD
      totalLiquidityETH
      hourStartUnix
    }
    singleTokenLockDayData(orderBy: $dayDataOrderBy, limit: $dayDataLimit) {
      id
      totalLiquidity
      totalLiquidityUSD
      totalLiquidityETH
      date
    }
  }
}
    `;
var StableSwapByIdDocument = gql`
    query stableSwapById($id: String!, $hourDataOrderBy: [StableSwapHourDataOrderByInput!], $hourDataLimit: Int, $dayDataOrderBy: [StableSwapDayDataOrderByInput!], $dayDataLimit: Int) {
  stableSwapById(id: $id) {
    id
    address
    lpToken
    lpTotalSupply
    tokens
    balances
    swapFee
    tvlUSD
    farm {
      id
      pid
      incentives {
        id
        rewardPerDay
        rewardToken {
          id
          name
          decimals
          symbol
        }
      }
      stakeApr
    }
    stableSwapHourData(orderBy: $hourDataOrderBy, limit: $hourDataLimit) {
      id
      hourStartUnix
      hourlyVolumeUSD
      tvlUSD
    }
    stableSwapDayData(orderBy: $dayDataOrderBy, limit: $dayDataLimit) {
      id
      tvlUSD
      dailyVolumeUSD
      date
    }
  }
}
    `;
var StableSwapsDocument = gql`
    query stableSwaps($limit: Int, $orderBy: [StableSwapOrderByInput!], $hourDataOrderBy: [StableSwapHourDataOrderByInput!], $hourDataLimit: Int, $dayDataOrderBy: [StableSwapDayDataOrderByInput!], $dayDataLimit: Int) {
  stableSwaps(limit: $limit, orderBy: $orderBy) {
    id
    address
    lpToken
    lpTotalSupply
    tokens
    balances
    swapFee
    tvlUSD
    farm {
      id
      pid
      incentives {
        id
        rewardPerDay
        rewardToken {
          id
          name
          decimals
          symbol
        }
      }
      stakeApr
    }
    stableSwapHourData(orderBy: $hourDataOrderBy, limit: $hourDataLimit) {
      id
      hourStartUnix
      hourlyVolumeUSD
      tvlUSD
    }
    stableSwapDayData(orderBy: $dayDataOrderBy, limit: $dayDataLimit) {
      id
      tvlUSD
      dailyVolumeUSD
      date
    }
  }
}
    `;
var TokenPricesDocument = gql`
    query tokenPrices($where: TokenWhereInput, $limit: Int) {
  tokens(where: $where, limit: $limit) {
    id
    derivedETH
    totalLiquidity
  }
  bundleById(id: "1") {
    ethPrice
  }
}
    `;
var TokensDocument = gql`
    query tokens($ids: [String!], $limit: Int) {
  tokens(where: {id_in: $ids}, limit: $limit) {
    id
    symbol
    name
    decimals
  }
}
    `;
var TxStatusDocument = gql`
    query txStatus($hash: String) {
  extrinsics(limit: 1, where: {hash_eq: $hash}) {
    id
    hash
    success
    block {
      height
      timestamp
    }
  }
}
    `;
var UserPoolsDocument = gql`
    query userPools($id: String!, $pairPositionsWhere: LiquidityPositionWhereInput, $pairPositionsLimit: Int, $pairDayDataOrderBy: [PairDayDataOrderByInput!], $pairDayDataLimit: Int, $stableSwapPositionsWhere: StableSwapLiquidityPositionWhereInput, $stableSwapPositionsLimit: Int, $stableSwapDayDataOrderBy: [StableSwapDayDataOrderByInput!], $stableSwapDayDataLimit: Int) {
  userById(id: $id) {
    liquidityPositions(where: $pairPositionsWhere, limit: $pairPositionsLimit) {
      id
      liquidityTokenBalance
      pair {
        token0 {
          id
          name
          decimals
          symbol
        }
        token1 {
          id
          name
          decimals
          symbol
        }
        id
        totalSupply
        reserve0
        reserve1
        reserveUSD
        pairDayData(orderBy: $pairDayDataOrderBy, limit: $pairDayDataLimit) {
          id
          dailyVolumeUSD
          reserveUSD
          date
        }
        farm {
          id
          stakeApr
        }
      }
    }
    stakePositions {
      id
      liquidityStakedBalance
      farm {
        id
        pair {
          farm {
            id
            stakeApr
          }
          token0 {
            id
            name
            decimals
            symbol
          }
          token1 {
            id
            name
            decimals
            symbol
          }
          id
          totalSupply
          reserve0
          reserve1
          reserveUSD
          pairDayData(orderBy: $pairDayDataOrderBy, limit: $pairDayDataLimit) {
            id
            dailyVolumeUSD
            reserveUSD
            date
          }
        }
        singleTokenLock {
          id
          farm {
            id
            stakeApr
          }
          token {
            id
            name
            decimals
            symbol
          }
          id
          totalLiquidity
          totalLiquidityETH
          totalLiquidityUSD
        }
        stableSwap {
          id
          lpToken
          address
          lpTotalSupply
          tokens
          balances
          swapFee
          tvlUSD
          farm {
            id
            stakeApr
          }
        }
        stakeApr
      }
    }
    stableSwapLiquidityPositions(
      where: $stableSwapPositionsWhere
      limit: $stableSwapPositionsLimit
    ) {
      id
      liquidityTokenBalance
      stableSwap {
        id
        lpToken
        address
        lpTotalSupply
        tokens
        balances
        swapFee
        tvlUSD
        farm {
          id
          pair {
            token0 {
              id
              name
              decimals
              symbol
            }
            token1 {
              id
              name
              decimals
              symbol
            }
            id
            totalSupply
            reserve0
            reserve1
            reserveUSD
            pairDayData(orderBy: $pairDayDataOrderBy, limit: $pairDayDataLimit) {
              id
              dailyVolumeUSD
              reserveUSD
              date
            }
          }
          singleTokenLock {
            id
            token {
              id
              name
              decimals
              symbol
            }
            id
            totalLiquidity
            totalLiquidityETH
            totalLiquidityUSD
          }
          stableSwap {
            id
          }
          stakeApr
        }
        stableSwapDayData(
          orderBy: $stableSwapDayDataOrderBy
          limit: $stableSwapDayDataLimit
        ) {
          id
          tvlUSD
          dailyVolumeUSD
          date
        }
      }
    }
  }
}
    `;
var ZenlinkStatsDocument = gql`
    query zenlinkStats($id: String!) {
  zenlinkInfoById(id: $id) {
    totalTvlUSD
    totalVolumeUSD
  }
}
    `;
var ZenlinkTokenInfoDocument = gql`
    query zenlinkTokenInfo($id: String!) {
  zlkInfoById(id: $id) {
    id
    burn
    updatedDate
  }
}
    `;

// src/queries/daySnapshots.ts
var DAY_SNAPSHOTS = gql2`
  query daySnapshots(
    $limit: Int,
    $orderBy: [ZenlinkDayInfoOrderByInput!],
  ) {
    zenlinkDayInfos(orderBy: $orderBy, limit: $limit) {
      dailyVolumeUSD
      tvlUSD
      date
    }
  }
`;
var defaultDaySnapshotsFetcherParams = {
  limit: 1e3,
  orderBy: ["date_DESC" /* DateDesc */]
};
async function fetchDaySnapshots({ chainId, limit, orderBy }) {
  let data = null;
  let error = false;
  try {
    const { data: daySnapshots } = await LEGACY_CLIENTS[chainId].query({
      query: DAY_SNAPSHOTS,
      variables: {
        ...defaultDaySnapshotsFetcherParams,
        limit,
        orderBy
      }
    });
    data = daySnapshots.zenlinkDayInfos;
  } catch {
    error = true;
  }
  return wrapResultData(data, error);
}

// src/queries/market/marketDaySnapshots.ts
import { gql as gql4 } from "@apollo/client";

// src/__generated__/market-types.ts
import { gql as gql3 } from "@apollo/client";
import * as Apollo2 from "@apollo/client";
var MarketDaySnapshotsDocument = gql3`
    query marketDaySnapshots($limit: Int, $orderBy: [FactoryDayDataOrderByInput!]) {
  factoryDayData(orderBy: $orderBy, limit: $limit) {
    dailyVolumeUSD
    totalLiquidityUSD
    date
  }
}
    `;
var MarketPricesDocument = gql3`
    query marketPrices($where: MarketWhereInput, $limit: Int) {
  markets(where: $where, limit: $limit) {
    id
    priceUSD
    sy {
      id
      priceUSD
      baseAsset {
        id
        priceUSD
      }
      yieldToken {
        id
        priceUSD
      }
    }
    pt {
      id
      priceUSD
    }
    yt {
      id
      priceUSD
    }
  }
}
    `;
var MarketByIdDocument = gql3`
    query marketById($id: String!, $hourDataOrderBy: [MarketHourDataOrderByInput!], $hourDataLimit: Int, $dayDataOrderBy: [MarketDayDataOrderByInput!], $dayDataLimit: Int) {
  marketById(id: $id) {
    id
    reserveUSD
    priceUSD
    sy {
      id
      priceUSD
      baseAsset {
        id
        priceUSD
      }
      yieldToken {
        id
        priceUSD
      }
    }
    pt {
      id
      priceUSD
    }
    yt {
      id
      priceUSD
    }
    marketHourData(orderBy: $hourDataOrderBy, limit: $hourDataLimit) {
      id
      hourStartUnix
      reserveUSD
      hourlyVolumeUSD
    }
    marketDayData(orderBy: $dayDataOrderBy, limit: $dayDataLimit) {
      id
      date
      reserveUSD
      dailyVolumeUSD
      dailyFeeUSD
      underlyingAPY
      impliedAPY
      fixedROI
      longYieldROI
    }
  }
}
    `;
var MarketsDocument = gql3`
    query markets($limit: Int, $orderBy: [MarketOrderByInput!], $hourDataOrderBy: [MarketHourDataOrderByInput!], $hourDataLimit: Int, $dayDataOrderBy: [MarketDayDataOrderByInput!], $dayDataLimit: Int) {
  markets(limit: $limit, orderBy: $orderBy) {
    id
    reserveUSD
    priceUSD
    sy {
      id
      priceUSD
      baseAsset {
        id
        priceUSD
      }
      yieldToken {
        id
        priceUSD
      }
    }
    pt {
      id
      priceUSD
    }
    yt {
      id
      priceUSD
    }
    marketHourData(orderBy: $hourDataOrderBy, limit: $hourDataLimit) {
      id
      hourStartUnix
      reserveUSD
      hourlyVolumeUSD
    }
    marketDayData(orderBy: $dayDataOrderBy, limit: $dayDataLimit) {
      id
      date
      reserveUSD
      dailyVolumeUSD
      dailyFeeUSD
      underlyingAPY
      impliedAPY
      fixedROI
      longYieldROI
    }
  }
}
    `;
var VotePositionQueryDocument = gql3`
    query VotePositionQuery($timestampTo: BigInt!, $synchingIndexFrom: Int!) {
  votingEvents(
    limit: 1000
    where: {timestamp_lt: $timestampTo, syncingIndex_gte: $synchingIndexFrom}
    orderBy: syncingIndex_DESC
  ) {
    user
    bias
    slope
    timestamp
    pool
    syncingIndex
  }
}
    `;

// src/queries/market/marketDaySnapshots.ts
var MARKET_DAY_SNAPSHOTS = gql4`
  query marketDaySnapshots(
    $limit: Int,
    $orderBy: [FactoryDayDataOrderByInput!],
  ) {
    factoryDayData(orderBy: $orderBy, limit: $limit) {
      dailyVolumeUSD
      totalLiquidityUSD
      date
    }
  }
`;
var defaultDaySnapshotsFetcherParams2 = {
  limit: 1e3,
  orderBy: ["date_DESC" /* DateDesc */]
};
async function fetchMarketDaySnapshots({ chainId, limit, orderBy }) {
  let data = null;
  let error = false;
  try {
    const { data: daySnapshots } = await MARKET_CLIENTS[chainId].query({
      query: MARKET_DAY_SNAPSHOTS,
      variables: {
        ...defaultDaySnapshotsFetcherParams2,
        limit,
        orderBy
      }
    });
    data = daySnapshots.factoryDayData;
  } catch {
    error = true;
  }
  return wrapResultData(data, error);
}

// src/queries/market/marketPrices.ts
import { gql as gql5 } from "@apollo/client";
var MARKET_PRICES_FETCH = gql5`
  query marketPrices($where: MarketWhereInput, $limit: Int) {
    markets(where: $where, limit: $limit) {
      id
      priceUSD
      sy {
        id
        priceUSD
        baseAsset {
          id
          priceUSD
        }
        yieldToken {
          id
          priceUSD
        }
      }
      pt {
        id
        priceUSD
      }
      yt {
        id
        priceUSD
      }
    }
  }
`;
var defaultTokenPricesFetcherParams = {
  where: { priceUSD_gt: 0 },
  limit: 1e3
};
async function fetchMarketPrices(chainId) {
  let data = null;
  let error = false;
  try {
    const { data: tokenPricesData } = await MARKET_CLIENTS[chainId].query({
      query: MARKET_PRICES_FETCH,
      variables: defaultTokenPricesFetcherParams
    });
    data = tokenPricesData ?? null;
  } catch {
    error = true;
  }
  return wrapResultData(data, error);
}

// src/queries/market/markets.ts
import { gql as gql6 } from "@apollo/client";
var MARKET_BY_ID = gql6`
  query marketById(
    $id: String!, 
    $hourDataOrderBy: [MarketHourDataOrderByInput!],
    $hourDataLimit: Int,
    $dayDataOrderBy: [MarketDayDataOrderByInput!],
    $dayDataLimit: Int
  ) {
    marketById(id: $id) {
      id
      reserveUSD
      priceUSD
      sy {
        id
        priceUSD
        baseAsset {
          id
          priceUSD
        }
        yieldToken {
          id
          priceUSD
        }
      }
      pt {
        id
        priceUSD
      }
      yt {
        id
        priceUSD
      }
      marketHourData(orderBy: $hourDataOrderBy, limit: $hourDataLimit) {
        id
        hourStartUnix
        reserveUSD
        hourlyVolumeUSD
      }
      marketDayData(orderBy: $dayDataOrderBy, limit: $dayDataLimit) {
        id
        date
        reserveUSD
        dailyVolumeUSD
        dailyFeeUSD
        underlyingAPY
        impliedAPY
        fixedROI
        longYieldROI
      }
    }
  }
`;
var defaultMarketFetcherParams = {
  hourDataOrderBy: "hourStartUnix_DESC" /* HourStartUnixDesc */,
  hourDataLimit: 168,
  dayDataOrderBy: "date_DESC" /* DateDesc */,
  dayDataLimit: 750
};
async function fetchMarketById(chainId, id) {
  let data = null;
  let error = false;
  try {
    const { data: market } = await MARKET_CLIENTS[chainId].query({
      query: MARKET_BY_ID,
      variables: {
        ...defaultMarketFetcherParams,
        id
      }
    });
    data = market.marketById ?? null;
  } catch {
    error = true;
  }
  return wrapResultData(data, error);
}
var MARKETS = gql6`
  query markets(
    $limit: Int, 
    $orderBy: [MarketOrderByInput!],
    $hourDataOrderBy: [MarketHourDataOrderByInput!],
    $hourDataLimit: Int,
    $dayDataOrderBy: [MarketDayDataOrderByInput!],
    $dayDataLimit: Int
  ) {
    markets(limit: $limit, orderBy: $orderBy) {
      id
      reserveUSD
      priceUSD
      sy {
        id
        priceUSD
        baseAsset {
          id
          priceUSD
        }
        yieldToken {
          id
          priceUSD
        }
      }
      pt {
        id
        priceUSD
      }
      yt {
        id
        priceUSD
      }
      marketHourData(orderBy: $hourDataOrderBy, limit: $hourDataLimit) {
        id
        hourStartUnix
        reserveUSD
        hourlyVolumeUSD
      }
      marketDayData(orderBy: $dayDataOrderBy, limit: $dayDataLimit) {
        id
        date
        reserveUSD
        dailyVolumeUSD
        dailyFeeUSD
        underlyingAPY
        impliedAPY
        fixedROI
        longYieldROI
      }
    }
  }
`;
var defaultMarketsFetcherParams = {
  hourDataOrderBy: "hourStartUnix_DESC" /* HourStartUnixDesc */,
  hourDataLimit: 24,
  dayDataOrderBy: "date_DESC" /* DateDesc */,
  dayDataLimit: 7
};
async function fetchMarkets({
  chainId,
  limit,
  orderBy
}) {
  let data = null;
  let error = false;
  try {
    const { data: markets } = await MARKET_CLIENTS[chainId].query({
      query: MARKETS,
      variables: {
        ...defaultMarketsFetcherParams,
        limit,
        orderBy
      }
    });
    data = markets.markets;
  } catch (err) {
    error = true;
  }
  return wrapResultData(data, error);
}

// src/queries/market/votes.ts
import { gql as gql7 } from "@apollo/client";
var votingPositionQuery = gql7`
  query VotePositionQuery($timestampTo: BigInt!, $synchingIndexFrom: Int!) {
    votingEvents(
      limit: 1000
      where: { timestamp_lt: $timestampTo, syncingIndex_gte: $synchingIndexFrom }
      orderBy: syncingIndex_DESC
    ) {
      user
      bias
      slope
      timestamp
      pool
      syncingIndex
    }
  }
`;
async function fetchVotePositions(chainId, timestampTo) {
  const datas = [];
  let error = false;
  try {
    for (let synchingIndexFrom = 0; ; ) {
      const { data } = await MARKET_CLIENTS[chainId].query({
        query: votingPositionQuery,
        variables: {
          timestampTo: timestampTo.toString(),
          synchingIndexFrom
        }
      });
      for (const raw of data.votingEvents) {
        datas.push({
          pool: raw.pool,
          user: raw.user,
          bias: BigInt(raw.bias),
          slope: BigInt(raw.slope),
          timestamp: Number.parseInt(raw.timestamp)
        });
      }
      if (data.votingEvents.length < 1e3)
        break;
      synchingIndexFrom = data.votingEvents[999].syncingIndex;
    }
  } catch {
    error = true;
  }
  return wrapResultData(datas, error);
}

// src/queries/pairs.ts
import { gql as gql8 } from "@apollo/client";
import { ParachainId as ParachainId2 } from "@zenlink-interface/chain";
var PAIR_BY_ID = gql8`
  query pairById(
    $id: String!, 
    $hourDataOrderBy: [PairHourDataOrderByInput!],
    $hourDataLimit: Int,
    $dayDataOrderBy: [PairDayDataOrderByInput!],
    $dayDataLimit: Int
  ) {
    pairById(id: $id) {
      token0 {
        id
        name
        decimals
        symbol
      }
      token1 {
        id
        name
        decimals
        symbol
      }
      id
      totalSupply
      reserve0
      reserve1
      reserveUSD
      farm {
        id
        pid
        incentives {
          id
          rewardPerDay
          rewardToken {
            id
            name
            decimals
            symbol
          }
        }
        stakeApr
      }
      pairHourData(orderBy: $hourDataOrderBy, limit: $hourDataLimit) {
        id
        hourlyVolumeUSD
        reserveUSD
        hourStartUnix
      }
      pairDayData(orderBy: $dayDataOrderBy, limit: $dayDataLimit) {
        id
        dailyVolumeUSD
        reserveUSD
        date
      }
    }
  }
`;
var defaultPairFetcherParams = {
  hourDataOrderBy: "hourStartUnix_DESC" /* HourStartUnixDesc */,
  hourDataLimit: 168,
  dayDataOrderBy: "date_DESC" /* DateDesc */,
  dayDataLimit: 750
};
async function fetchPairById(chainId, id) {
  let data = null;
  let error = false;
  try {
    const { data: pair } = await LEGACY_CLIENTS[chainId].query({
      query: PAIR_BY_ID,
      variables: {
        ...defaultPairFetcherParams,
        id
      }
    });
    data = pair.pairById ?? null;
  } catch {
    error = true;
  }
  return wrapResultData(data, error);
}
var PAIRS = gql8`
  query pairs(
    $limit: Int, 
    $orderBy: [PairOrderByInput!],
    $hourDataOrderBy: [PairHourDataOrderByInput!],
    $hourDataLimit: Int,
    $dayDataOrderBy: [PairDayDataOrderByInput!],
    $dayDataLimit: Int
  ) {
    pairs(limit: $limit, orderBy: $orderBy) {
      token0 {
        id
        name
        decimals
        symbol
      }
      token1 {
        id
        name
        decimals
        symbol
      }
      id
      totalSupply
      reserve0
      reserve1
      reserveUSD
      farm {
        id
        pid
        incentives {
          id
          rewardPerDay
          rewardToken {
            id
            name
            decimals
            symbol
          }
        }
        stakeApr
      }
      pairHourData(orderBy: $hourDataOrderBy, limit: $hourDataLimit) {
        id
        hourlyVolumeUSD
        reserveUSD
        hourStartUnix
      }
      pairDayData(orderBy: $dayDataOrderBy, limit: $dayDataLimit) {
        id
        dailyVolumeUSD
        reserveUSD
        date
      }
    }
  }
`;
var defaultPairsFetcherParams = {
  hourDataOrderBy: "hourStartUnix_DESC" /* HourStartUnixDesc */,
  hourDataLimit: 24,
  dayDataOrderBy: "date_DESC" /* DateDesc */,
  dayDataLimit: 7
};
async function fetchPairs({
  chainId,
  limit,
  orderBy
}) {
  let data = null;
  let error = false;
  try {
    const { data: pairs } = await LEGACY_CLIENTS[chainId].query({
      query: PAIRS,
      variables: {
        ...defaultPairsFetcherParams,
        limit: chainId === ParachainId2.BIFROST_KUSAMA ? 70 : limit,
        orderBy
      }
    });
    data = pairs.pairs;
  } catch {
    error = true;
  }
  return wrapResultData(data, error);
}

// src/queries/singleTokenLocks.ts
import { gql as gql9 } from "@apollo/client";
import { ParachainId as ParachainId3 } from "@zenlink-interface/chain";
var SINGLE_TOKEN_LOCK_BY_ID = gql9`
  query singleTokenLockById(
    $id: String!, 
    $hourDataOrderBy: [SingleTokenLockHourDataOrderByInput!],
    $hourDataLimit: Int,
    $dayDataOrderBy: [SingleTokenLockDayDataOrderByInput!],
    $dayDataLimit: Int
  ) {
    singleTokenLockById(id: $id) {
      token {
        id
        name
        decimals
        symbol
      }
      id
      totalLiquidity
      totalLiquidityETH
      totalLiquidityUSD
      farm {
        id
        pid
        incentives {
          id
          rewardPerDay
          rewardToken {
            id
            name
            decimals
            symbol
          }
        }
        stakeApr
      }
      singleTokenLockHourData(orderBy: $hourDataOrderBy, limit: $hourDataLimit) {
        id
        totalLiquidity
        totalLiquidityUSD
        totalLiquidityETH
        hourStartUnix
      }
      singleTokenLockDayData(orderBy: $dayDataOrderBy, limit: $dayDataLimit) {
        id
        totalLiquidity
        totalLiquidityUSD
        totalLiquidityETH
        date
      }
    }
  }
`;
var defaultPairFetcherParams2 = {
  hourDataOrderBy: "hourStartUnix_DESC" /* HourStartUnixDesc */,
  hourDataLimit: 168,
  dayDataOrderBy: "date_DESC" /* DateDesc */,
  dayDataLimit: 750
};
async function fetchSingleTokenLockById(chainId, id) {
  let data = null;
  let error = false;
  try {
    const { data: pair } = await LEGACY_CLIENTS[chainId].query({
      query: SINGLE_TOKEN_LOCK_BY_ID,
      variables: {
        ...defaultPairFetcherParams2,
        id
      }
    });
    data = pair.singleTokenLockById ?? null;
  } catch {
    error = true;
  }
  return wrapResultData(data, error);
}
var SINGLE_TOKEN_LOCKS = gql9`
  query singleTokenLocks(
    $limit: Int, 
    $orderBy: [SingleTokenLockOrderByInput!],
    $hourDataOrderBy: [SingleTokenLockHourDataOrderByInput!],
    $hourDataLimit: Int,
    $dayDataOrderBy: [SingleTokenLockDayDataOrderByInput!],
    $dayDataLimit: Int
  ) {
    singleTokenLocks(limit: $limit, orderBy: $orderBy) {
      token {
        id
        name
        decimals
        symbol
      }
      id
      totalLiquidity
      totalLiquidityETH
      totalLiquidityUSD
      farm {
        id
        pid
        incentives {
          id
          rewardPerDay
          rewardToken {
            symbol
          }
        }
        stakeApr
      }
      singleTokenLockHourData(orderBy: $hourDataOrderBy, limit: $hourDataLimit) {
        id
        totalLiquidity
        totalLiquidityUSD
        totalLiquidityETH
        hourStartUnix
      }
      singleTokenLockDayData(orderBy: $dayDataOrderBy, limit: $dayDataLimit) {
        id
        totalLiquidity
        totalLiquidityUSD
        totalLiquidityETH
        date
      }
    }
  }
`;
var defaultPairsFetcherParams2 = {
  hourDataOrderBy: "hourStartUnix_DESC" /* HourStartUnixDesc */,
  hourDataLimit: 24,
  dayDataOrderBy: "date_DESC" /* DateDesc */,
  dayDataLimit: 7
};
async function fetchSingleTokenLocks({
  chainId,
  limit,
  orderBy
}) {
  let data = null;
  let error = false;
  try {
    const { data: result } = await LEGACY_CLIENTS[chainId].query({
      query: SINGLE_TOKEN_LOCKS,
      variables: {
        ...defaultPairsFetcherParams2,
        limit: chainId === ParachainId3.BIFROST_KUSAMA ? 70 : limit,
        orderBy
      }
    });
    data = result.singleTokenLocks;
  } catch {
    error = true;
  }
  return wrapResultData(data, error);
}

// src/queries/stableSwaps.ts
import { gql as gql10 } from "@apollo/client";
import { ParachainId as ParachainId4 } from "@zenlink-interface/chain";
var STABLESWAP_BY_ID = gql10`
  query stableSwapById(
    $id: String!,
    $hourDataOrderBy: [StableSwapHourDataOrderByInput!],
    $hourDataLimit: Int,
    $dayDataOrderBy: [StableSwapDayDataOrderByInput!],
    $dayDataLimit: Int
  ) {
    stableSwapById(id: $id) {
      id
      address
      lpToken
      lpTotalSupply
      tokens
      balances
      swapFee
      tvlUSD
      farm {
        id
        pid
        incentives {
          id
          rewardPerDay
          rewardToken {
            id
            name
            decimals
            symbol
          }
        }
        stakeApr
      }
      stableSwapHourData(orderBy: $hourDataOrderBy, limit: $hourDataLimit) {
        id
        hourStartUnix
        hourlyVolumeUSD
        tvlUSD
      }
      stableSwapDayData(orderBy: $dayDataOrderBy, limit: $dayDataLimit) {
        id
        tvlUSD
        dailyVolumeUSD
        date
      }
    }
  }
`;
var defaultStableSwapFetcherParams = {
  hourDataOrderBy: "hourStartUnix_DESC" /* HourStartUnixDesc */,
  hourDataLimit: 168,
  dayDataOrderBy: "date_DESC" /* DateDesc */,
  dayDataLimit: 750
};
async function fetchStableSwapById(chainId, id) {
  let data = null;
  let error = false;
  try {
    const { data: stableSwap } = await LEGACY_CLIENTS[chainId].query({
      query: STABLESWAP_BY_ID,
      variables: {
        ...defaultStableSwapFetcherParams,
        id
      }
    });
    data = stableSwap.stableSwapById ?? null;
  } catch {
    error = true;
  }
  return wrapResultData(data, error);
}
var STABLESWAPS = gql10`
  query stableSwaps(
    $limit: Int, 
    $orderBy: [StableSwapOrderByInput!],
    $hourDataOrderBy: [StableSwapHourDataOrderByInput!],
    $hourDataLimit: Int,
    $dayDataOrderBy: [StableSwapDayDataOrderByInput!],
    $dayDataLimit: Int
  ) {
    stableSwaps(limit: $limit, orderBy: $orderBy) {
      id
      address
      lpToken
      lpTotalSupply
      tokens
      balances
      swapFee
      tvlUSD
      farm {
        id
        pid
        incentives {
          id
          rewardPerDay
          rewardToken {
            id
            name
            decimals
            symbol
          }
        }
        stakeApr
      }
      stableSwapHourData(orderBy: $hourDataOrderBy, limit: $hourDataLimit) {
        id
        hourStartUnix
        hourlyVolumeUSD
        tvlUSD
      }
      stableSwapDayData(orderBy: $dayDataOrderBy, limit: $dayDataLimit) {
        id
        tvlUSD
        dailyVolumeUSD
        date
      }
    }
  }
`;
var defaultStableSwapsFetcherParams = {
  hourDataOrderBy: "hourStartUnix_DESC" /* HourStartUnixDesc */,
  hourDataLimit: 24,
  dayDataOrderBy: "date_DESC" /* DateDesc */,
  dayDataLimit: 7
};
async function fetchStableSwaps({
  chainId,
  limit,
  orderBy
}) {
  let data = null;
  let error = false;
  try {
    const { data: stableSwaps } = await LEGACY_CLIENTS[chainId].query({
      query: STABLESWAPS,
      variables: {
        ...defaultStableSwapsFetcherParams,
        limit: chainId === ParachainId4.BIFROST_KUSAMA ? 70 : limit,
        orderBy
      }
    });
    data = stableSwaps.stableSwaps;
  } catch {
    error = true;
  }
  return wrapResultData(data, error);
}

// src/queries/tokenPrices.ts
import { gql as gql11 } from "@apollo/client";
var TOKEN_PRICES_FETCH = gql11`
  query tokenPrices($where: TokenWhereInput, $limit: Int) {
    tokens(where: $where, limit: $limit) {
      id
      derivedETH
      totalLiquidity
    }
    bundleById(id: "1") {
      ethPrice
    }
  }
`;
var defaultTokenPricesFetcherParams2 = {
  where: { derivedETH_gt: "0.000000" },
  limit: 1e3
};
async function fetchTokenPrices(chainId) {
  let data = null;
  let error = false;
  try {
    const { data: tokenPricesData } = await LEGACY_CLIENTS[chainId].query({
      query: TOKEN_PRICES_FETCH,
      variables: defaultTokenPricesFetcherParams2
    });
    data = tokenPricesData ?? null;
  } catch {
    error = true;
  }
  return wrapResultData(data, error);
}

// src/queries/tokens.ts
import { gql as gql12 } from "@apollo/client";
var TOKENS_BY_IDS = gql12`
  query tokens($ids: [String!], $limit: Int) {
    tokens(where: { id_in: $ids }, limit: $limit) {
      id
      symbol
      name
      decimals
    }
  }
`;
async function fetchTokensByIds(chainId, ids) {
  let data = null;
  let error = false;
  try {
    const { data: tokensData } = await LEGACY_CLIENTS[chainId].query({
      query: TOKENS_BY_IDS,
      variables: {
        ids: Array.from(ids.values()),
        limit: 1e3
      }
    });
    data = tokensData.tokens;
  } catch {
    error = true;
  }
  return wrapResultData(data, error);
}

// src/queries/txStatus.ts
import { gql as gql13 } from "@apollo/client";
var TX_STATUS_QUERY = gql13`
  query txStatus($hash: String) {
    extrinsics(limit: 1, where: { hash_eq: $hash }) {
      id
      hash
      success
      block {
        height
        timestamp
      }
    }
  }
`;
async function fetchTxStatus(chainId, hash) {
  let data = null;
  let error = false;
  try {
    const { data: txStatusData } = await ARCHIVE_CLIENTS[chainId].query({
      query: TX_STATUS_QUERY,
      variables: {
        hash
      }
    });
    data = txStatusData.extrinsics[0];
  } catch {
    error = true;
  }
  return wrapResultData(data, error);
}

// src/queries/uniswap/univ3TokenPrices.ts
import { gql as gql14 } from "@apollo/client";
var UNIV3_TOKEN_PRICES_FETCH = gql14`
  query uniV3TokenPrices($where: Token_filter, $first: Int) {
    tokens(where: $where, first: $first) {
      id
      derivedETH
      totalSupply
    }
    bundle(id: "1") {
      ethPriceUSD
    }
  }
`;
var defaultTokenPricesFetcherParams3 = {
  where: { derivedETH_gt: "0", totalValueLockedUSD_gt: "1000" },
  first: 1e3
};
async function fetchUniV3TokenPrices(chainId) {
  let data = null;
  let error = false;
  try {
    const { data: tokenPricesData } = await LEGACY_CLIENTS[chainId].query({
      query: UNIV3_TOKEN_PRICES_FETCH,
      variables: defaultTokenPricesFetcherParams3
    });
    data = tokenPricesData ?? null;
  } catch {
    error = true;
  }
  return wrapResultData(data, error);
}

// src/queries/userPools.ts
import { gql as gql15 } from "@apollo/client";

// src/utils/encodeChainAddress.ts
import { encodeAddress } from "@polkadot/util-crypto";
import { chains, isSubstrateNetwork } from "@zenlink-interface/chain";
function encodeChainAddress(address, chainId) {
  return isSubstrateNetwork(chainId) ? encodeAddress(address, chains[chainId]?.prefix) : address;
}

// src/queries/userPools.ts
var USER_POOLS_FETCH = gql15`
  query userPools(
    $id: String!,
    $pairPositionsWhere: LiquidityPositionWhereInput,
    $pairPositionsLimit: Int,
    $pairDayDataOrderBy: [PairDayDataOrderByInput!],
    $pairDayDataLimit: Int,
    $stableSwapPositionsWhere: StableSwapLiquidityPositionWhereInput,
    $stableSwapPositionsLimit: Int,
    $stableSwapDayDataOrderBy: [StableSwapDayDataOrderByInput!],
    $stableSwapDayDataLimit: Int,
  ) {
    userById(id: $id) {
      liquidityPositions(
        where: $pairPositionsWhere, 
        limit: $pairPositionsLimit
      ) {
        id
        liquidityTokenBalance
        pair {
          token0 {
            id
            name
            decimals
            symbol
          }
          token1 {
            id
            name
            decimals
            symbol
          }
          id
          totalSupply
          reserve0
          reserve1
          reserveUSD
          pairDayData(
            orderBy: $pairDayDataOrderBy, 
            limit: $pairDayDataLimit
          ) {
            id
            dailyVolumeUSD
            reserveUSD
            date
          }
          farm {
            id
            stakeApr
          }
        }
      }
      stakePositions {
        id
        liquidityStakedBalance
        farm {
          id
          pair {
            farm {
              id
              stakeApr
            }
            token0 {
              id
              name
              decimals
              symbol
            }
            token1 {
              id
              name
              decimals
              symbol
            }
            id
            totalSupply
            reserve0
            reserve1
            reserveUSD
            pairDayData(
              orderBy: $pairDayDataOrderBy, 
              limit: $pairDayDataLimit
            ) {
              id
              dailyVolumeUSD
              reserveUSD
              date
            }
          }
          singleTokenLock {
            id
            farm {
              id
              stakeApr
            }
            token {
              id
              name
              decimals
              symbol
            }
            id
            totalLiquidity
            totalLiquidityETH
            totalLiquidityUSD
          }
          stableSwap {
            id
            lpToken
            address
            lpTotalSupply
            tokens
            balances
            swapFee
            tvlUSD
            farm {
              id
              stakeApr
            }
          }
          stakeApr
        }
      }
      stableSwapLiquidityPositions(
        where: $stableSwapPositionsWhere, 
        limit: $stableSwapPositionsLimit
      ) {
        id
        liquidityTokenBalance
        stableSwap {
          id
          lpToken
          address
          lpTotalSupply
          tokens
          balances
          swapFee
          tvlUSD
          farm {
            id
            pair {
              token0 {
                id
                name
                decimals
                symbol
              }
              token1 {
                id
                name
                decimals
                symbol
              }
              id
              totalSupply
              reserve0
              reserve1
              reserveUSD
              pairDayData(
                orderBy: $pairDayDataOrderBy, 
                limit: $pairDayDataLimit
              ) {
                id
                dailyVolumeUSD
                reserveUSD
                date
              }
            }
            singleTokenLock {
              id
              token {
                id
                name
                decimals
                symbol
              }
              id
              totalLiquidity
              totalLiquidityETH
              totalLiquidityUSD
            }
            stableSwap {
              id
            }
            stakeApr
          },
          stableSwapDayData(
            orderBy: $stableSwapDayDataOrderBy, 
            limit: $stableSwapDayDataLimit
          ) {
            id
            tvlUSD
            dailyVolumeUSD
            date
          }
        }
      }
    }
  }
`;
var defaultUserPoolsFetcherParams = {
  pairPositionsWhere: { liquidityTokenBalance_gt: "0" },
  pairPositionsLimit: 100,
  pairDayDataOrderBy: "date_DESC" /* DateDesc */,
  pairDayDataLimit: 7,
  stableSwapPositionsWhere: { liquidityTokenBalance_gt: "0" },
  stableSwapPositionsLimit: 100,
  stableSwapDayDataOrderBy: "date_DESC" /* DateDesc */,
  stableSwapDayDataLimit: 7
};
async function fetchUserPools(chainId, user) {
  const address = encodeChainAddress(user, chainId);
  let data = null;
  let error = false;
  try {
    const { data: userPoolsData } = await LEGACY_CLIENTS[chainId].query({
      query: USER_POOLS_FETCH,
      variables: {
        ...defaultUserPoolsFetcherParams,
        id: address
      }
    });
    data = userPoolsData.userById ?? null;
  } catch {
    error = true;
  }
  return wrapResultData(data, error);
}

// src/queries/zenlinkInfos.ts
import { gql as gql16 } from "@apollo/client";
var ZENLINK_STATS = gql16`
  query zenlinkStats($id: String!) {
    zenlinkInfoById(id: $id) {
      totalTvlUSD
      totalVolumeUSD
    }
  }
`;
async function fetchZenlinkStats(chainId) {
  let data;
  let error = false;
  try {
    const { data: zenlinkStats } = await LEGACY_CLIENTS[chainId].query({
      query: ZENLINK_STATS,
      variables: { id: "1" }
    });
    data = zenlinkStats.zenlinkInfoById;
  } catch (e) {
    error = true;
  }
  return wrapResultData(data, error);
}
var ZLK_TOKEN_INFO = gql16`
  query zenlinkTokenInfo($id: String!) {
    zlkInfoById(id: $id) {
      id
      burn
      updatedDate
    }
  }
`;
async function fetchZLKTokenInfo(chainId) {
  let data;
  let error = false;
  try {
    const { data: result } = await LEGACY_CLIENTS[chainId].query({
      query: ZLK_TOKEN_INFO,
      variables: { id: "1" }
    });
    data = result.zlkInfoById;
  } catch (e) {
    error = true;
  }
  return wrapResultData(data, error);
}

// src/queries/index.ts
function wrapResultData(data, error) {
  return { data: data || void 0, error: data ? false : error };
}

// src/resolvers/query/daySnapshotsByChainIds.ts
import { chainName, chainShortName } from "@zenlink-interface/chain";
import { ZENLINK_ENABLED_NETWORKS } from "@zenlink-interface/graph-config";
async function daySnapshotsByChainIds({
  chainIds,
  limit = 1e3,
  orderBy = "date_DESC" /* DateDesc */
}) {
  const daySnapshotsTransformer = (snapshotMetas, chainId) => snapshotMetas.map((snapshotMeta) => ({
    ...snapshotMeta,
    chainId,
    chainName: chainName[chainId],
    chainShortName: chainShortName[chainId]
  }));
  return Promise.allSettled([
    ...chainIds.filter((el) => ZENLINK_ENABLED_NETWORKS.includes(el)).map(
      (chainId) => fetchDaySnapshots({ chainId, limit, orderBy }).then(
        (data) => data.data ? daySnapshotsTransformer(data.data, chainId) : []
      )
    )
  ]).then(
    (daySnapshots) => daySnapshots.flat().reduce((previousValue, currentValue) => {
      if (currentValue.status === "fulfilled")
        previousValue.push(...currentValue.value);
      return previousValue;
    }, [])
  );
}

// src/resolvers/query/liquidityPositions.ts
import { STABLE_SWAP_FEE_NUMBER, STANDARD_SWAP_FEE_NUMBER } from "@zenlink-interface/amm";
import { chainName as chainName2, chainShortName as chainShortName2 } from "@zenlink-interface/chain";
import { ZENLINK_ENABLED_NETWORKS as ZENLINK_ENABLED_NETWORKS2 } from "@zenlink-interface/graph-config";
import omit from "lodash.omit";

// src/types.ts
var POOL_TYPE = /* @__PURE__ */ ((POOL_TYPE2) => {
  POOL_TYPE2["STANDARD_POOL"] = "STANDARD_POOL";
  POOL_TYPE2["STABLE_POOL"] = "STABLE_POOL";
  POOL_TYPE2["SINGLE_TOKEN_POOL"] = "SINGLE_TOKEN_POOL";
  return POOL_TYPE2;
})(POOL_TYPE || {});

// src/resolvers/query/liquidityPositions.ts
async function standardLiquidityPositionTransformer(liquidityPosition, stakePosition, chainId) {
  const unstaked = liquidityPosition.map((position) => {
    return {
      id: position.id,
      pair: position.pair,
      balance: position.liquidityTokenBalance
    };
  });
  const staked = stakePosition.map((position) => {
    return {
      id: position.id,
      pair: position.farm.pair,
      balance: position.liquidityStakedBalance
    };
  });
  const positionMap = {};
  const pairTransformer = (pair, chainId2) => {
    const vloumeUSDOneWeek = pair.pairDayData.slice(0, 7).reduce((total, current) => total + Number(current.dailyVolumeUSD), 0);
    const feeApr = Number(pair?.reserveUSD) > 500 ? vloumeUSDOneWeek * STANDARD_SWAP_FEE_NUMBER * 365 / (Number(pair?.reserveUSD) * 7) : 0;
    const farms = pair.farm ?? [];
    const bestStakeApr = farms.reduce((best, cur) => {
      const stakeApr = Number(cur.stakeApr);
      return stakeApr > best ? stakeApr : best;
    }, 0);
    const apr = Number(feeApr) + bestStakeApr;
    const volume1d = 0;
    const volume7d = 0;
    const fees1d = volume1d * STANDARD_SWAP_FEE_NUMBER;
    const fees7d = volume7d * STANDARD_SWAP_FEE_NUMBER;
    return {
      liquidityTokenBalance: "0",
      stakedBalance: "0",
      unstakedBalance: "0",
      type: "STANDARD_POOL" /* STANDARD_POOL */,
      id: `${chainShortName2[chainId2]}:${pair.id}`,
      chainId: chainId2,
      chainName: chainName2[chainId2],
      chainShortName: chainShortName2[chainId2],
      balance: Number(0),
      valueUSD: Number(0) * Number(pair.reserveUSD) / Number(pair.totalSupply),
      pool: {
        ...omit(pair, ["pairDayData", "farm"]),
        type: "STANDARD_POOL" /* STANDARD_POOL */,
        name: `${pair.token0.symbol}-${pair.token1.symbol}`,
        address: pair.id,
        id: `${chainShortName2[chainId2]}:${pair.id}`,
        chainId: chainId2,
        chainName: chainName2[chainId2],
        chainShortName: chainShortName2[chainId2],
        token0: {
          ...pair.token0,
          chainId: chainId2
        },
        token1: {
          ...pair.token1,
          chainId: chainId2
        },
        poolHourData: [],
        poolDayData: pair.pairDayData,
        apr,
        bestStakeApr,
        swapFee: STANDARD_SWAP_FEE_NUMBER,
        feeApr,
        volume1d,
        volume7d,
        fees1d,
        fees7d
      }
    };
  };
  unstaked.forEach((us) => {
    const position = positionMap[us.pair.id];
    if (!position)
      positionMap[us.pair.id] = pairTransformer(us.pair, chainId);
    const positionEntry = positionMap[us.pair.id];
    positionEntry.liquidityTokenBalance = us.balance;
    positionEntry.stakedBalance = us.balance;
    positionEntry.balance = Number(us.balance);
    positionEntry.valueUSD = Number(positionEntry.liquidityTokenBalance) * Number(us.pair.reserveUSD) / Number(us.pair.totalSupply);
  });
  staked.forEach((s) => {
    if (!s.pair)
      return;
    const position = positionMap[s.pair.id];
    if (!position)
      positionMap[s.pair.id] = pairTransformer(s.pair, chainId);
    const positionEntry = positionMap[s.pair.id];
    positionEntry.liquidityTokenBalance = (BigInt(s.balance) + BigInt(positionEntry.liquidityTokenBalance)).toString();
    positionEntry.stakedBalance = (BigInt(s.balance) + BigInt(positionEntry.stakedBalance)).toString();
    positionEntry.balance = Number(positionEntry.liquidityTokenBalance);
    positionEntry.valueUSD = Number(positionEntry.liquidityTokenBalance) * Number(s.pair.reserveUSD) / Number(s.pair.totalSupply);
  });
  return Object.entries(positionMap).map((p) => p[1]).filter((p) => p.balance > 0);
}
async function singleTokenLockLiquidityPositionTransformer(stakePosition, chainId) {
  const staked = stakePosition.filter((position) => !!position.farm.singleTokenLock).map((position) => ({
    id: position.id,
    singleTokenLock: position.farm.singleTokenLock,
    balance: position.liquidityStakedBalance
  }));
  const positionMap = {};
  const singleTokenLockTransformer = (singleTokenLock, chainId2) => {
    const feeApr = 0;
    const farms = singleTokenLock.farm ?? [];
    const bestStakeApr = farms.reduce((best, cur) => {
      const stakeApr = Number(cur.stakeApr);
      return stakeApr > best ? stakeApr : best;
    }, 0);
    const apr = Number(feeApr) + bestStakeApr;
    const volume1d = 0;
    const volume7d = 0;
    const fees1d = 0;
    const fees7d = 0;
    return {
      liquidityTokenBalance: "0",
      stakedBalance: "0",
      unstakedBalance: "0",
      type: "SINGLE_TOKEN_POOL" /* SINGLE_TOKEN_POOL */,
      id: `${chainShortName2[chainId2]}:${singleTokenLock.id}`,
      chainId: chainId2,
      chainName: chainName2[chainId2],
      chainShortName: chainShortName2[chainId2],
      balance: Number(0),
      valueUSD: Number(0) * Number(singleTokenLock.totalLiquidityUSD) / Number(singleTokenLock.totalLiquidity),
      pool: {
        ...singleTokenLock,
        ...omit(singleTokenLock, ["pairDayData", "farm", "reserveUSD"]),
        type: "SINGLE_TOKEN_POOL" /* SINGLE_TOKEN_POOL */,
        name: `${singleTokenLock.token.symbol}`,
        address: singleTokenLock.id,
        id: `${chainShortName2[chainId2]}:${singleTokenLock.id}`,
        chainId: chainId2,
        chainName: chainName2[chainId2],
        chainShortName: chainShortName2[chainId2],
        token: {
          ...singleTokenLock.token,
          chainId: chainId2
        },
        poolHourData: [],
        poolDayData: [],
        reserveUSD: "0",
        apr,
        bestStakeApr,
        farm: [],
        swapFee: 0,
        feeApr,
        volume1d,
        volume7d,
        fees1d,
        fees7d
      }
    };
  };
  staked.forEach((item) => {
    if (!item.singleTokenLock)
      return;
    const position = positionMap[item.singleTokenLock.id];
    if (!position)
      positionMap[item.singleTokenLock.id] = singleTokenLockTransformer(item.singleTokenLock, chainId);
    const positionEntry = positionMap[item.singleTokenLock.id];
    positionEntry.liquidityTokenBalance = (BigInt(item.balance) + BigInt(positionEntry.liquidityTokenBalance)).toString();
    positionEntry.stakedBalance = (BigInt(item.balance) + BigInt(positionEntry.stakedBalance)).toString();
    positionEntry.balance = Number(positionEntry.liquidityTokenBalance);
    positionEntry.valueUSD = Number(positionEntry.liquidityTokenBalance) * Number(item.singleTokenLock.totalLiquidityUSD) / Number(item.singleTokenLock.totalLiquidity);
  });
  return Object.entries(positionMap).map((item) => item[1]).filter((item) => item.balance > 0) ?? [];
}
async function stableLiquidityPositionTransformer(liquidityPosition, stakePosition, chainId, tokenMetaMap = {}) {
  const unstaked = liquidityPosition.map((position) => ({
    id: position.id,
    stableSwap: position.stableSwap,
    balance: position.liquidityTokenBalance
  }));
  const staked = stakePosition.map((position) => ({
    id: position.id,
    stableSwap: position.farm.stableSwap,
    balance: position.liquidityStakedBalance
  })).filter((item) => !!item.stableSwap);
  const positionMap = {};
  const stableSwapTransformer = (stableSwap, chainId2) => {
    const vloumeUSDOneWeek = stableSwap.stableSwapDayData.slice(0, 7).reduce((total, current) => total + Number(current.dailyVolumeUSD), 0);
    const feeApr = Number(stableSwap?.tvlUSD) > 500 ? vloumeUSDOneWeek * STABLE_SWAP_FEE_NUMBER * 365 / (Number(stableSwap?.tvlUSD) * 7) : 0;
    const farms = stableSwap.farm ?? [];
    const bestStakeApr = farms.reduce((best, cur) => {
      const stakeApr = Number(cur.stakeApr);
      return stakeApr > best ? stakeApr : best;
    }, 0);
    const apr = Number(feeApr) + bestStakeApr;
    const volume1d = 0;
    const volume7d = 0;
    const fees1d = volume1d * STANDARD_SWAP_FEE_NUMBER;
    const fees7d = volume7d * STANDARD_SWAP_FEE_NUMBER;
    return {
      ...stableSwap,
      liquidityTokenBalance: "0",
      stakedBalance: "0",
      unstakedBalance: "0",
      type: "STABLE_POOL" /* STABLE_POOL */,
      id: `${chainShortName2[chainId2]}:${stableSwap.id}`,
      chainId: chainId2,
      chainName: chainName2[chainId2],
      chainShortName: chainShortName2[chainId2],
      balance: Number(0),
      valueUSD: Number(0) * Number(stableSwap.tvlUSD) / Number(stableSwap.lpTotalSupply),
      pool: {
        ...omit(stableSwap, ["stableSwapDayData", "farm"]),
        type: "STABLE_POOL" /* STABLE_POOL */,
        reserveUSD: stableSwap.tvlUSD,
        name: "4pool",
        // TODO: Generate different names for the pools
        id: `${chainShortName2[chainId2]}:${stableSwap.id}`,
        tokens: [...stableSwap.tokens].map((tokenAddress) => Object.assign(tokenMetaMap[tokenAddress], { chainId: chainId2 })),
        chainId: chainId2,
        farm: [],
        chainName: chainName2[chainId2],
        chainShortName: chainShortName2[chainId2],
        apr,
        bestStakeApr,
        swapFee: STABLE_SWAP_FEE_NUMBER,
        feeApr,
        volume1d,
        volume7d,
        fees1d,
        fees7d,
        poolHourData: [],
        poolDayData: [...stableSwap.stableSwapDayData || []].map((data) => ({
          ...data,
          reserveUSD: data.tvlUSD
        }))
      }
    };
  };
  unstaked.forEach((item) => {
    const position = positionMap[item.stableSwap.id];
    if (!position)
      positionMap[item.stableSwap.id] = stableSwapTransformer(item.stableSwap, chainId);
    const positionEntry = positionMap[item.stableSwap.id];
    positionEntry.liquidityTokenBalance = item.balance;
    positionEntry.stakedBalance = item.balance;
    positionEntry.balance = Number(item.balance);
    positionEntry.valueUSD = Number(positionEntry.liquidityTokenBalance) * Number(item.stableSwap.tvlUSD) / Number(item.stableSwap.lpTotalSupply);
  });
  staked.forEach((item) => {
    if (!item.stableSwap)
      return;
    const position = positionMap[item.stableSwap.id];
    if (!position)
      positionMap[item.stableSwap.id] = stableSwapTransformer(item.stableSwap, chainId);
    const positionEntry = positionMap[item.stableSwap.id];
    positionEntry.liquidityTokenBalance = (BigInt(item.balance) + BigInt(positionEntry.liquidityTokenBalance)).toString();
    positionEntry.stakedBalance = (BigInt(item.balance) + BigInt(positionEntry.stakedBalance)).toString();
    positionEntry.balance = Number(positionEntry.liquidityTokenBalance);
    positionEntry.valueUSD = Number(positionEntry.liquidityTokenBalance) * Number(item.stableSwap.tvlUSD) / Number(item.stableSwap.lpTotalSupply);
  });
  return Object.entries(positionMap).map((item) => item[1]).filter((item) => item.balance > 0);
}
async function liquidityPositions(chainIds, user) {
  return Promise.allSettled([
    ...chainIds.filter((el) => ZENLINK_ENABLED_NETWORKS2.includes(el)).map(
      (chainId) => fetchUserPools(chainId, user).then(async (data) => {
        const standardLiquidityPosition = await standardLiquidityPositionTransformer(data?.data?.liquidityPositions ?? [], data?.data?.stakePositions ?? [], chainId);
        const singleTokenLiquidityPosition = await singleTokenLockLiquidityPositionTransformer(data.data?.stakePositions ?? [], chainId);
        if (data.data?.stableSwapLiquidityPositions.length) {
          const { stableSwapLiquidityPositions } = data.data;
          const tokens = /* @__PURE__ */ new Set();
          stableSwapLiquidityPositions.forEach(
            (position) => position.stableSwap.tokens.forEach((token) => tokens.add(token))
          );
          const tokenMetas = await fetchTokensByIds(chainId, tokens);
          const tokenMetaMap = tokenMetas.data?.reduce((map, current) => {
            if (!map[current.id])
              map[current.id] = current;
            return map;
          }, {}) ?? {};
          const stableSwapLiquidityPosition = await stableLiquidityPositionTransformer(data.data?.stableSwapLiquidityPositions ?? [], data?.data?.stakePositions ?? [], chainId, tokenMetaMap);
          return [
            ...stableSwapLiquidityPosition,
            ...standardLiquidityPosition,
            ...singleTokenLiquidityPosition
          ];
        } else {
          return data.data ? [
            ...standardLiquidityPosition,
            ...singleTokenLiquidityPosition
          ] : [];
        }
      })
    )
  ]).then(
    (positions) => positions.flat().reduce((previousValue, currentValue) => {
      if (currentValue.status === "fulfilled")
        previousValue.push(...currentValue.value);
      return previousValue;
    }, [])
  );
}

// src/resolvers/query/markets/marketById.ts
import { chainName as chainName3, chainShortName as chainShortName3, ParachainId as ParachainId5 } from "@zenlink-interface/chain";
async function marketById(id) {
  const chainId = ParachainId5.MOONBEAM;
  const marketTransformer = (marketMeta, chainId2) => {
    const underlyingAPY = marketMeta.marketDayData[0]?.underlyingAPY;
    const impliedAPY = marketMeta.marketDayData[0]?.impliedAPY;
    const fixedROI = marketMeta.marketDayData[0]?.fixedROI;
    const longYieldROI = marketMeta.marketDayData[0]?.longYieldROI;
    return {
      ...marketMeta,
      id: marketMeta.id,
      address: marketMeta.id,
      chainId: chainId2,
      chainName: chainName3[chainId2],
      chainShortName: chainShortName3[chainId2],
      underlyingAPY: underlyingAPY || 0,
      impliedAPY: impliedAPY || 0,
      fixedROI: fixedROI || 0,
      longYieldROI: longYieldROI || 0
    };
  };
  return fetchMarketById(chainId, id).then((data) => data.data ? marketTransformer(data.data, chainId) : void 0);
}

// src/resolvers/query/markets/marketDaySnapshots.ts
import { chainName as chainName4, chainShortName as chainShortName4 } from "@zenlink-interface/chain";
import { ZENLINK_ENABLED_NETWORKS as ZENLINK_ENABLED_NETWORKS3 } from "@zenlink-interface/graph-config";
async function marketDaySnapshotsByChainIds({
  chainIds,
  limit = 1e3,
  orderBy = "date_DESC" /* DateDesc */
}) {
  const daySnapshotsTransformer = (snapshotMetas, chainId) => snapshotMetas.map((snapshotMeta) => ({
    ...snapshotMeta,
    chainId,
    chainName: chainName4[chainId],
    chainShortName: chainShortName4[chainId]
  }));
  return Promise.allSettled([
    ...chainIds.filter((el) => ZENLINK_ENABLED_NETWORKS3.includes(el)).map(
      (chainId) => fetchMarketDaySnapshots({ chainId, limit, orderBy }).then(
        (data) => data.data ? daySnapshotsTransformer(data.data, chainId) : []
      )
    )
  ]).then(
    (daySnapshots) => daySnapshots.flat().reduce((previousValue, currentValue) => {
      if (currentValue.status === "fulfilled")
        previousValue.push(...currentValue.value);
      return previousValue;
    }, [])
  );
}

// src/resolvers/query/markets/marketsByChainIds.ts
import { chainName as chainName5, chainShortName as chainShortName5 } from "@zenlink-interface/chain";
import { ZENLINK_ENABLED_NETWORKS as ZENLINK_ENABLED_NETWORKS4 } from "@zenlink-interface/graph-config";
async function marketsByChainIds({
  chainIds,
  limit = 200,
  orderBy = "reserveUSD_DESC" /* ReserveUsdDesc */
}) {
  const marketsTransformer = (marketMetas, chainId) => marketMetas.map((marketMeta) => {
    const underlyingAPY = marketMeta.marketDayData[0]?.underlyingAPY;
    const impliedAPY = marketMeta.marketDayData[0]?.impliedAPY;
    const fixedROI = marketMeta.marketDayData[0]?.fixedROI;
    const longYieldROI = marketMeta.marketDayData[0]?.longYieldROI;
    return {
      ...marketMeta,
      id: `${chainShortName5[chainId]}:${marketMeta.id}`,
      address: marketMeta.id,
      chainId,
      chainName: chainName5[chainId],
      chainShortName: chainShortName5[chainId],
      underlyingAPY: underlyingAPY || 0,
      impliedAPY: impliedAPY || 0,
      fixedROI: fixedROI || 0,
      longYieldROI: longYieldROI || 0
    };
  });
  return Promise.allSettled([
    ...chainIds.filter((el) => ZENLINK_ENABLED_NETWORKS4.includes(el)).map(
      (chainId) => fetchMarkets({ chainId, limit, orderBy }).then(
        (data) => data.data ? marketsTransformer(data.data, chainId) : []
      )
    )
  ]).then(
    (markets) => markets.flat().reduce((previousValue, currentValue) => {
      if (currentValue.status === "fulfilled")
        previousValue.push(...currentValue.value);
      return previousValue;
    }, [])
  );
}

// src/resolvers/query/pairById.ts
import { STANDARD_SWAP_FEE_NUMBER as STANDARD_SWAP_FEE_NUMBER2 } from "@zenlink-interface/amm";
import { chainName as chainName6, chainShortNameToChainId } from "@zenlink-interface/chain";
import omit2 from "lodash.omit";
async function pairById(id) {
  const [chainShortName9, address] = id.split(":");
  const chainId = chainShortNameToChainId[chainShortName9];
  const pairTransformer = (pair, chainId2) => {
    const vloumeUSDOneWeek = pair.pairDayData.slice(0, 7).reduce((total, current) => total + Number(current.dailyVolumeUSD), 0);
    const feeApr = Number(pair.reserveUSD) > 500 ? vloumeUSDOneWeek * STANDARD_SWAP_FEE_NUMBER2 * 365 / (Number(pair.reserveUSD) * 7) : 0;
    const farms = pair.farm ?? [];
    const bestStakeApr = farms.reduce((best, cur) => {
      const stakeApr = Number(cur.stakeApr);
      return stakeApr > best ? stakeApr : best;
    }, 0);
    const apr = Number(feeApr) + bestStakeApr;
    const currentHourIndex = Number.parseInt(((/* @__PURE__ */ new Date()).getTime() / 36e5).toString(), 10);
    const hourStartUnix = Number(currentHourIndex - 24) * 36e5;
    const volume1d = pair.pairHourData.filter((hourData) => Number(hourData.hourStartUnix) >= hourStartUnix).reduce((volume, { hourlyVolumeUSD }) => volume + Number(hourlyVolumeUSD), 0);
    const volume7d = pair.pairDayData.slice(0, 7).reduce((volume, { dailyVolumeUSD }) => volume + Number(dailyVolumeUSD), 0);
    const fees1d = volume1d * STANDARD_SWAP_FEE_NUMBER2;
    const fees7d = volume7d * STANDARD_SWAP_FEE_NUMBER2;
    return {
      ...omit2(pair, ["pairHourData", "pairDayData", "farm"]),
      type: "STANDARD_POOL" /* STANDARD_POOL */,
      name: `${pair.token0.symbol}-${pair.token1.symbol}`,
      address: pair.id,
      id: `${chainShortName9}:${pair.id}`,
      chainId: chainId2,
      chainName: chainName6[chainId2],
      chainShortName: chainShortName9,
      token0: {
        ...pair.token0,
        chainId: chainId2
      },
      token1: {
        ...pair.token1,
        chainId: chainId2
      },
      farm: pair.farm,
      poolHourData: pair.pairHourData,
      poolDayData: pair.pairDayData,
      apr,
      swapFee: STANDARD_SWAP_FEE_NUMBER2,
      feeApr,
      volume1d,
      volume7d,
      fees1d,
      bestStakeApr,
      fees7d
    };
  };
  return fetchPairById(chainId, address).then((data) => data.data ? pairTransformer(data.data, chainId) : void 0);
}

// src/resolvers/query/pairsByChainIds.ts
import { STANDARD_SWAP_FEE_NUMBER as STANDARD_SWAP_FEE_NUMBER3 } from "@zenlink-interface/amm";
import { chainName as chainName7, chainShortName as chainShortName6 } from "@zenlink-interface/chain";
import { ZENLINK_ENABLED_NETWORKS as ZENLINK_ENABLED_NETWORKS5 } from "@zenlink-interface/graph-config";
import omit3 from "lodash.omit";
async function pairsByChainIds({
  chainIds,
  limit = 200,
  orderBy = "reserveUSD_DESC" /* ReserveUsdDesc */
}) {
  const pairsTransformer = (pairMetas, chainId) => pairMetas.map((pairMeta) => {
    const vloumeUSDOneWeek = pairMeta.pairDayData.slice(0, 7).reduce((total, current) => total + Number(current.dailyVolumeUSD), 0);
    const feeApr = Number(pairMeta?.reserveUSD) > 500 ? vloumeUSDOneWeek * STANDARD_SWAP_FEE_NUMBER3 * 365 / (Number(pairMeta?.reserveUSD) * 7) : 0;
    const farms = pairMeta.farm ?? [];
    const bestStakeApr = farms.reduce((best, cur) => {
      const stakeApr = Number(cur.stakeApr);
      return stakeApr > best ? stakeApr : best;
    }, 0);
    const apr = Number(feeApr) + bestStakeApr;
    const currentHourIndex = Number.parseInt(((/* @__PURE__ */ new Date()).getTime() / 36e5).toString(), 10);
    const hourStartUnix = Number(currentHourIndex - 24) * 36e5;
    const volume1d = pairMeta.pairHourData.filter((hourData) => Number(hourData.hourStartUnix) >= hourStartUnix).reduce((volume, { hourlyVolumeUSD }) => volume + Number(hourlyVolumeUSD), 0);
    const volume7d = pairMeta.pairDayData.slice(0, 7).reduce((volume, { dailyVolumeUSD }) => volume + Number(dailyVolumeUSD), 0);
    const fees1d = volume1d * STANDARD_SWAP_FEE_NUMBER3;
    const fees7d = volume7d * STANDARD_SWAP_FEE_NUMBER3;
    return {
      ...omit3(pairMeta, ["pairHourData", "pairDayData", "farm"]),
      type: "STANDARD_POOL" /* STANDARD_POOL */,
      name: `${pairMeta.token0.symbol}-${pairMeta.token1.symbol}`,
      address: pairMeta.id,
      id: `${chainShortName6[chainId]}:${pairMeta.id}`,
      chainId,
      chainName: chainName7[chainId],
      chainShortName: chainShortName6[chainId],
      token0: {
        ...pairMeta.token0,
        chainId
      },
      token1: {
        ...pairMeta.token1,
        chainId
      },
      farm: pairMeta.farm,
      poolHourData: pairMeta.pairHourData || [],
      poolDayData: pairMeta.pairDayData || [],
      apr,
      swapFee: STANDARD_SWAP_FEE_NUMBER3,
      feeApr,
      bestStakeApr,
      volume1d,
      volume7d,
      fees1d,
      fees7d
    };
  });
  return Promise.allSettled([
    ...chainIds.filter((el) => ZENLINK_ENABLED_NETWORKS5.includes(el)).map(
      (chainId) => fetchPairs({ chainId, limit, orderBy }).then(
        (data) => data.data ? pairsTransformer(data.data, chainId).filter(({ reserve0, reserve1 }) => Number(reserve0) > 0 && Number(reserve1) > 0) : []
      )
    )
  ]).then(
    (pairs) => pairs.flat().reduce((previousValue, currentValue) => {
      if (currentValue.status === "fulfilled")
        previousValue.push(...currentValue.value);
      return previousValue;
    }, [])
  );
}

// src/resolvers/query/singleTokenLockById.ts
import { chainName as chainName8, chainShortNameToChainId as chainShortNameToChainId2 } from "@zenlink-interface/chain";
import omit4 from "lodash.omit";
async function singleTokenLockById(id) {
  const [chainShortName9, address] = id.split(":");
  const chainId = chainShortNameToChainId2[chainShortName9];
  const singleTokenLockTransformer = (queryMeta, chainId2) => {
    const feeApr = 0;
    const volume1d = 0;
    const volume7d = 0;
    const fees1d = 0;
    const fees7d = 0;
    const farms = queryMeta.farm ?? [];
    const bestStakeApr = farms.reduce((best, cur) => {
      const stakeApr = Number(cur.stakeApr);
      return stakeApr > best ? stakeApr : best;
    }, 0);
    const apr = Number(feeApr) + bestStakeApr;
    return {
      ...omit4(queryMeta, ["singleTokenLockHourData", "singleTokenLockDayData", "farm"]),
      type: "SINGLE_TOKEN_POOL" /* SINGLE_TOKEN_POOL */,
      name: `${queryMeta?.token?.symbol}`,
      address: queryMeta.id,
      id: `${chainShortName9}:${queryMeta.id}`,
      chainId: chainId2,
      chainName: chainName8[chainId2],
      chainShortName: chainShortName9,
      token: {
        ...queryMeta.token,
        chainId: chainId2
      },
      poolHourData: (queryMeta.singleTokenLockHourData || []).map((item) => ({
        ...item,
        hourlyVolumeUSD: "0",
        reserveUSD: item.totalLiquidityUSD
      })),
      poolDayData: (queryMeta.singleTokenLockDayData || []).map((item) => ({
        ...item,
        dailyVolumeUSD: "0",
        reserveUSD: item.totalLiquidityUSD
      })),
      farm: queryMeta.farm,
      apr,
      bestStakeApr,
      reserveUSD: queryMeta.totalLiquidityUSD,
      swapFee: 0,
      feeApr,
      volume1d,
      volume7d,
      fees1d,
      fees7d
    };
  };
  return fetchSingleTokenLockById(chainId, address).then((data) => data.data ? singleTokenLockTransformer(data.data, chainId) : void 0);
}

// src/resolvers/query/singleTokenLocksByChainIds.ts
import { chainName as chainName9, chainShortName as chainShortName7 } from "@zenlink-interface/chain";
import { ZENLINK_ENABLED_NETWORKS as ZENLINK_ENABLED_NETWORKS6 } from "@zenlink-interface/graph-config";
import omit5 from "lodash.omit";
async function singleTokenLocksByChainIds({
  chainIds,
  limit = 200,
  orderBy = "totalLiquidityUSD_DESC" /* TotalLiquidityUsdDesc */
}) {
  const singleTokenLocksTransformer = (queryMetas, chainId) => queryMetas.map((queryMeta) => {
    const feeApr = 0;
    const volume1d = 0;
    const volume7d = 0;
    const fees1d = 0;
    const fees7d = 0;
    const farms = queryMeta.farm ?? [];
    const bestStakeApr = farms.reduce((best, cur) => {
      const stakeApr = Number(cur.stakeApr);
      return stakeApr > best ? stakeApr : best;
    }, 0);
    const apr = Number(feeApr) + bestStakeApr;
    return {
      ...omit5(queryMeta, ["singleTokenLockHourData", "singleTokenLockDayData", "farm"]),
      type: "SINGLE_TOKEN_POOL" /* SINGLE_TOKEN_POOL */,
      name: `${queryMeta?.token?.symbol}`,
      address: queryMeta.id,
      id: `${chainShortName7[chainId]}:${queryMeta.id}`,
      chainId,
      chainName: chainName9[chainId],
      chainShortName: chainShortName7[chainId],
      token: {
        ...queryMeta.token,
        chainId
      },
      poolHourData: (queryMeta.singleTokenLockHourData || []).map((item) => ({
        ...item,
        hourlyVolumeUSD: "0",
        reserveUSD: item.totalLiquidityUSD
      })),
      poolDayData: (queryMeta.singleTokenLockDayData || []).map((item) => ({
        ...item,
        dailyVolumeUSD: "0",
        reserveUSD: item.totalLiquidityUSD
      })),
      farm: queryMeta.farm,
      apr,
      bestStakeApr,
      reserveUSD: queryMeta.totalLiquidityUSD,
      swapFee: 0,
      feeApr,
      volume1d,
      volume7d,
      fees1d,
      fees7d
    };
  });
  return Promise.allSettled([
    ...chainIds.filter((el) => ZENLINK_ENABLED_NETWORKS6.includes(el)).map(
      (chainId) => fetchSingleTokenLocks({ chainId, limit, orderBy }).then(
        (data) => data.data ? singleTokenLocksTransformer(data.data, chainId) : []
      )
    )
  ]).then(
    (pairs) => pairs.flat().reduce((previousValue, currentValue) => {
      if (currentValue.status === "fulfilled") {
        const value = currentValue.value;
        previousValue.push(...value);
      }
      return previousValue;
    }, [])
  );
}

// src/resolvers/query/stableSwapById.ts
import { STABLE_SWAP_FEE_NUMBER as STABLE_SWAP_FEE_NUMBER2 } from "@zenlink-interface/amm";
import { chainName as chainName10, chainShortNameToChainId as chainShortNameToChainId3 } from "@zenlink-interface/chain";
import omit6 from "lodash.omit";
async function stableSwapById(id) {
  const [chainShortName9, address] = id.split(":");
  const chainId = chainShortNameToChainId3[chainShortName9];
  const stableSwapTransformer = async (stableSwap, chainId2) => {
    const vloumeUSDOneWeek = stableSwap.stableSwapDayData.slice(0, 7).reduce((total, current) => total + Number(current.dailyVolumeUSD), 0);
    const feeApr = Number(stableSwap.tvlUSD) > 500 ? vloumeUSDOneWeek * STABLE_SWAP_FEE_NUMBER2 * 365 / (Number(stableSwap.tvlUSD) * 7) : 0;
    const currentHourIndex = Number.parseInt(((/* @__PURE__ */ new Date()).getTime() / 36e5).toString(), 10);
    const hourStartUnix = Number(currentHourIndex - 24) * 36e5;
    const volume1d = stableSwap.stableSwapHourData.filter((hourData) => Number(hourData.hourStartUnix) >= hourStartUnix).reduce((volume, { hourlyVolumeUSD }) => volume + Number(hourlyVolumeUSD), 0);
    const volume7d = stableSwap.stableSwapDayData.slice(0, 7).reduce((volume, { dailyVolumeUSD }) => volume + Number(dailyVolumeUSD), 0);
    const fees1d = volume1d * STABLE_SWAP_FEE_NUMBER2;
    const fees7d = volume7d * STABLE_SWAP_FEE_NUMBER2;
    const farms = stableSwap.farm ?? [];
    const bestStakeApr = farms.reduce((best, cur) => {
      const stakeApr = Number(cur.stakeApr);
      return stakeApr > best ? stakeApr : best;
    }, 0);
    const apr = Number(feeApr) + bestStakeApr;
    const tokens = /* @__PURE__ */ new Set();
    stableSwap.tokens.forEach((token) => tokens.add(token));
    const tokenMetas = await fetchTokensByIds(chainId2, tokens);
    const tokenMetaMap = tokenMetas.data?.reduce((map, current) => {
      if (!map[current.id])
        map[current.id] = current;
      return map;
    }, {}) ?? {};
    return {
      ...omit6(stableSwap, ["stableSwapDayData", "stableSwapHourData", "farm"]),
      type: "STABLE_POOL" /* STABLE_POOL */,
      name: "4pool",
      id: `${chainShortName9}:${stableSwap.id}`,
      chainId: chainId2,
      chainName: chainName10[chainId2],
      chainShortName: chainShortName9,
      reserveUSD: stableSwap.tvlUSD,
      tokens: [...stableSwap.tokens].map((tokenAddress) => Object.assign(tokenMetaMap[tokenAddress], { chainId: chainId2 })),
      apr,
      bestStakeApr,
      feeApr,
      swapFee: STABLE_SWAP_FEE_NUMBER2,
      farm: stableSwap.farm,
      volume1d,
      volume7d,
      fees1d,
      fees7d,
      poolHourData: [...stableSwap.stableSwapHourData || []].map((data) => ({
        ...data,
        reserveUSD: data.tvlUSD
      })),
      poolDayData: [...stableSwap.stableSwapDayData || []].map((data) => ({
        ...data,
        reserveUSD: data.tvlUSD
      }))
    };
  };
  return fetchStableSwapById(chainId, address).then(async (data) => data.data ? await stableSwapTransformer(data.data, chainId) : void 0);
}

// src/resolvers/query/stableSwapsByChainIds.ts
import { STABLE_SWAP_FEE_NUMBER as STABLE_SWAP_FEE_NUMBER3 } from "@zenlink-interface/amm";
import { chainName as chainName11, chainShortName as chainShortName8 } from "@zenlink-interface/chain";
import { ZENLINK_ENABLED_NETWORKS as ZENLINK_ENABLED_NETWORKS7 } from "@zenlink-interface/graph-config";
import omit7 from "lodash.omit";
async function stableSwapsByChainIds({
  chainIds,
  limit = 10,
  orderBy = "tvlUSD_DESC" /* TvlUsdDesc */
}) {
  const stableSwapsTransformer = async (stableSwapMetas, chainId) => {
    const tokens = /* @__PURE__ */ new Set();
    stableSwapMetas.forEach((stableSwap) => stableSwap.tokens.forEach((token) => tokens.add(token)));
    const tokenMetas = await fetchTokensByIds(chainId, tokens);
    const tokenMetaMap = tokenMetas.data?.reduce((map, current) => {
      if (!map[current.id])
        map[current.id] = current;
      return map;
    }, {}) ?? {};
    return stableSwapMetas.map((stableSwapMeta) => {
      const vloumeUSDOneWeek = stableSwapMeta.stableSwapDayData.slice(0, 7).reduce((total, current) => total + Number(current.dailyVolumeUSD), 0);
      const feeApr = Number(stableSwapMeta.tvlUSD) > 500 ? vloumeUSDOneWeek * STABLE_SWAP_FEE_NUMBER3 * 365 / (Number(stableSwapMeta.tvlUSD) * 7) : 0;
      const currentHourIndex = Number.parseInt(((/* @__PURE__ */ new Date()).getTime() / 36e5).toString(), 10);
      const hourStartUnix = Number(currentHourIndex - 24) * 36e5;
      const volume1d = stableSwapMeta.stableSwapHourData.filter((hourData) => Number(hourData.hourStartUnix) >= hourStartUnix).reduce((volume, { hourlyVolumeUSD }) => volume + Number(hourlyVolumeUSD), 0);
      const volume7d = stableSwapMeta.stableSwapDayData.slice(0, 7).reduce((volume, { dailyVolumeUSD }) => volume + Number(dailyVolumeUSD), 0);
      const fees1d = volume1d * STABLE_SWAP_FEE_NUMBER3;
      const fees7d = volume7d * STABLE_SWAP_FEE_NUMBER3;
      const farms = stableSwapMeta.farm ?? [];
      const bestStakeApr = farms.reduce((best, cur) => {
        const stakeApr = Number(cur.stakeApr);
        return stakeApr > best ? stakeApr : best;
      }, 0);
      const apr = Number(feeApr) + bestStakeApr;
      return {
        ...omit7(stableSwapMeta, ["stableSwapDayData", "stableSwapHourData", "farm"]),
        type: "STABLE_POOL" /* STABLE_POOL */,
        name: "4pool",
        id: `${chainShortName8[chainId]}:${stableSwapMeta.id}`,
        chainId,
        chainName: chainName11[chainId],
        chainShortName: chainShortName8[chainId],
        reserveUSD: stableSwapMeta.tvlUSD,
        tokens: [...stableSwapMeta.tokens].map((tokenAddress) => Object.assign(tokenMetaMap[tokenAddress], { chainId })),
        apr,
        bestStakeApr,
        swapFee: STABLE_SWAP_FEE_NUMBER3,
        feeApr,
        volume1d,
        volume7d,
        fees1d,
        fees7d,
        farm: stableSwapMeta.farm,
        poolHourData: [...stableSwapMeta.stableSwapHourData || []].map((data) => ({
          ...data,
          reserveUSD: data.tvlUSD
        })),
        poolDayData: [...stableSwapMeta.stableSwapDayData || []].map((data) => ({
          ...data,
          reserveUSD: data.tvlUSD
        }))
      };
    });
  };
  return Promise.allSettled([
    ...chainIds.filter((el) => ZENLINK_ENABLED_NETWORKS7.includes(el)).map(
      (chainId) => fetchStableSwaps({ chainId, limit, orderBy }).then(
        async (data) => data.data ? (await stableSwapsTransformer(data.data, chainId)).filter(({ lpTotalSupply }) => Number(lpTotalSupply) > 0) : []
      )
    )
  ]).then(
    (pairs) => pairs.flat().reduce((previousValue, currentValue) => {
      if (currentValue.status === "fulfilled")
        previousValue.push(...currentValue.value);
      return previousValue;
    }, [])
  );
}

// src/resolvers/query/txStatusByHash.ts
async function txStatus(chainId, hash, onStatus) {
  onStatus("loading");
  const { data, error } = await fetchTxStatus(chainId, hash);
  if (error)
    return;
  if (data) {
    onStatus(data.success ? "success" : "error");
  } else {
    const interval = setInterval(async () => {
      const { data: data2, error: error2 } = await fetchTxStatus(chainId, hash);
      if (error2)
        clearInterval(interval);
      if (data2) {
        onStatus(data2.success ? "success" : "error");
        clearInterval(interval);
      }
    }, 12e3);
  }
}
export {
  ARCHIVE_CLIENTS,
  DEFAULT_CLIENT_OPTIONS,
  LEGACY_CLIENTS,
  MARKET_CLIENTS,
  POOL_TYPE,
  daySnapshotsByChainIds,
  encodeChainAddress,
  fetchDaySnapshots,
  fetchMarketById,
  fetchMarketDaySnapshots,
  fetchMarketPrices,
  fetchMarkets,
  fetchPairById,
  fetchPairs,
  fetchSingleTokenLockById,
  fetchSingleTokenLocks,
  fetchStableSwapById,
  fetchStableSwaps,
  fetchTokenPrices,
  fetchTokensByIds,
  fetchTxStatus,
  fetchUniV3TokenPrices,
  fetchUserPools,
  fetchVotePositions,
  fetchZLKTokenInfo,
  fetchZenlinkStats,
  liquidityPositions,
  marketById,
  marketDaySnapshotsByChainIds,
  marketsByChainIds,
  pairById,
  pairsByChainIds,
  singleTokenLockById,
  singleTokenLocksByChainIds,
  stableSwapById,
  stableSwapsByChainIds,
  txStatus,
  wrapResultData
};
