"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseSwapProvider = void 0;
const chain_1 = require("@zenlink-interface/chain");
const LiquidityProvider_1 = require("./LiquidityProvider");
const UniswapV2Base_1 = require("./UniswapV2Base");
class BaseSwapProvider extends UniswapV2Base_1.UniswapV2BaseProvider {
    constructor(chainId, client) {
        super(chainId, client);
        this.factory = {
            [chain_1.ParachainId.BASE]: '0xFDa619b6d20975be80A10332cD39b9a4b0FAa8BB',
        };
        this.initCodeHash = {
            [chain_1.ParachainId.BASE]: '0xb618a2730fae167f5f8ac7bd659dd8436d571872655bcb6fd11f2158c8a64a3b',
        };
    }
    getType() {
        return LiquidityProvider_1.LiquidityProviders.BaseSwap;
    }
    getPoolProviderName() {
        return 'BaseSwap';
    }
}
exports.BaseSwapProvider = BaseSwapProvider;
