"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STABLE_SWAP_FEE_NUMBER = exports.STANDARD_SWAP_FEE_NUMBER = exports.STABLE_SWAP_FEE_FRACTION = exports.STANDARD_SWAP_FEE_FRACTION = exports.Fee = void 0;
const math_1 = require("@zenlink-interface/math");
// Fee - Tiers TBD
var Fee;
(function (Fee) {
    Fee[Fee["LOW"] = 1] = "LOW";
    Fee[Fee["MEDIUM"] = 5] = "MEDIUM";
    Fee[Fee["AVERAGE"] = 10] = "AVERAGE";
    Fee[Fee["DEFAULT"] = 30] = "DEFAULT";
    Fee[Fee["HIGH"] = 100] = "HIGH";
})(Fee || (exports.Fee = Fee = {}));
exports.STANDARD_SWAP_FEE_FRACTION = new math_1.Fraction(30, 10000);
exports.STABLE_SWAP_FEE_FRACTION = new math_1.Fraction(5, 10000);
exports.STANDARD_SWAP_FEE_NUMBER = Number(exports.STANDARD_SWAP_FEE_FRACTION.toSignificant(3));
exports.STABLE_SWAP_FEE_NUMBER = Number(exports.STABLE_SWAP_FEE_FRACTION.toSignificant(3));
