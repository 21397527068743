// src/address.ts
import { getAddress as getEvmAddress, isAddress as isEvmAddress } from "@ethersproject/address";
function shortenAddress(address, characters = 4) {
  try {
    const parsed = getAddress(address);
    return `${parsed.substring(0, characters + 2)}...${parsed.substring(address.length - characters)}`;
  } catch {
    return `${address.substring(0, characters + 2)}...${address.substring(address.length - characters)}`;
  }
}
function isAddress(address) {
  return isZenlinkAddress(address) || isEvmAddress(address);
}
function getAddress(address) {
  if (!isAddress(address))
    return address;
  if (isEvmAddress(address))
    return getEvmAddress(address);
  return address;
}
function isZenlinkAddress(address) {
  return /^\d+(-\d+)(-\d+)$/.test(address);
}
function addressToZenlinkAssetId(address) {
  if (!isZenlinkAddress(address))
    throw new Error("invalid address");
  const [chainId, assetType, assetIndex] = address.split("-");
  return {
    chainId: Number(chainId),
    assetType: Number(assetType),
    assetIndex: Number(assetIndex)
  };
}
function zenlinkAssetIdToAddress({ chainId, assetType, assetIndex }) {
  return `${chainId}-${assetType}-${assetIndex}`;
}

// src/name.ts
function shortenName(name, characters = 10) {
  if (!name)
    return "";
  return name.length <= characters ? name : `${name.substring(0, characters)}...`;
}

// src/number.ts
import numeral from "numeral";
function formatNumber(value) {
  return numeral(value).format("(0.00a)");
}
function formatFullNumber(value) {
  return numeral(value).format("0,0");
}
function formatTransactionAmount(num, maxDigits = 9) {
  if (num === 0)
    return "0.00";
  if (!num)
    return "";
  if (num < 1e-5)
    return "<0.00001";
  if (num >= 1e-5 && num < 1) {
    return `${Number(num.toFixed(5)).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 5
    })}`;
  }
  if (num >= 1 && num < 1e4) {
    return `${Number(num.toPrecision(6)).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 6
    })}`;
  }
  if (num >= 1e4 && num < 1e6)
    return `${Number(num.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2 })}`;
  if (num >= 10 ** (maxDigits - 1))
    return `${num.toExponential(maxDigits - 3)}`;
  return `${Number(num.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2 })}`;
}

// src/percent.ts
import numeral2 from "numeral";
var formatPercent = (value) => numeral2(value).format("0.00%");

// src/price.ts
import numeral3 from "numeral";
function formatUSD(value, inputString = "$0.00a", full = false) {
  if (!full && Number(value) < 0.01)
    return "$0.00";
  return numeral3(value).format(inputString);
}
export {
  addressToZenlinkAssetId,
  formatFullNumber,
  formatNumber,
  formatPercent,
  formatTransactionAmount,
  formatUSD,
  getAddress,
  isAddress,
  isZenlinkAddress,
  shortenAddress,
  shortenName,
  zenlinkAssetIdToAddress
};
